import React, { useEffect, useState } from 'react';

import DropzoneArea from 'components/controls/dropzone/Dropzone';
import TextArea from 'components/controls/textarea/TextArea';
import TextInput from 'components/controls/textInput/TextInput';

import AutocompleteInput from 'components/controls/autocompleteInput/AutocompleteInput';
import AutocompleteInputSelects from 'components/controls/autocompleteInput/AutocompleteInputSelects';

import dashboardStyle from 'styles/dashboard';

import { AntSwitch } from 'components/toggle/switch';

import { FormLabel, Grid } from '@mui/material';

import Stack from '@mui/material/Stack';

import { getProductCategories } from 'services/productService';

import { ProductWithPoster } from 'models/ProductsModel';
import { content_rating, languages_list } from 'utils/utils';

import { allRegionsList } from 'utils/regionsList';

import { useQuery } from 'react-query';
import { isSeller } from 'services/authService';
import { getCollections } from 'services/collectionService';
import { showField } from 'utils/projectFieldsDefinitions';

interface Props {
  product: ProductWithPoster;
  handleDrop: any;
  handleChange: any;
  handleLangChange: any;
  handleRatingChange: any;
  handleGenresChange: any;
  handleRegionChange: any;
  handleCollectionSelect: any;
  edit: boolean;
  classes?: any;
}

const FilmDetails: React.FC<Props> = ({
  product,
  handleDrop,
  handleChange,
  handleLangChange,
  handleRatingChange,
  handleGenresChange,
  handleRegionChange,
  handleCollectionSelect,
  edit,
}) => {
  const classes = dashboardStyle();
  const [publish, setPublish] = useState(false);
  const [genreList, setGenreList] = useState<string[]>([]);
  const [selectedGenre, setSelectedGenre] = useState(
    product.genres ? product.genres[0] : null
  );
  const langOptions = languages_list;
  const selectedLangValue = langOptions.find(
    (option) => option.name === product.language
  );
  const ratingOptions = content_rating;
  const selectedRatingValue = ratingOptions.find(
    (option) => option.code === product.contentRating
  );

  const [regionsList, setRegionsList] = useState<string[]>(allRegionsList);
  const [selectedRegions, setSelectedRegions] = useState();

  //---- Collections -------------
  const [selectedCollection, setSelectedCollection] = useState<any[]>(
    product.associatedCollections || []
  );
  const [collections, setCollections] = useState<any[]>([]);
  console.log('colecoes', collections);

  const {
    data: dataCollections,
    isLoading: loadingCollections,
    isError: errorCollections,
  } = useQuery(['collections', isSeller()], () => getCollections(isSeller()), {
    onSuccess: (data) => {
      const collectionsData = data.collections;
      console.log('collection data', collectionsData);
      setCollections(collectionsData);
      ////
      if (!edit) {
        const associatedCollectionObjects = product.associatedCollections
          ? product.associatedCollections
              .map((id: string) =>
                data.collections.find((col: any) => col.id === id)
              )
              .filter((col: any) => col)
          : [];
        setSelectedCollection(associatedCollectionObjects);
      }
      ////
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    if (edit) {
      setSelectedCollection(product.associatedCollections || []);
    }
  }, [product.associatedCollections]);

  const handleCollectionSelectVal = (event: any, newValue: any[]) => {
    setSelectedCollection(newValue);
    handleCollectionSelect(newValue);
  };
  // ----------------------------------

  useEffect(() => {
    const fetchGenreList = async () => {
      try {
        const value = await getProductCategories();
        const genreNames = value.categories.map((category) => category.name);
        setGenreList(genreNames);
      } catch (error) {
        console.log(error);
      }
    };
    fetchGenreList();
  }, []);

  return (
    <React.Fragment>
      <div>
        <Grid container>
          <Grid container spacing={3}>
            {/*LEFT*/}
            <Grid item container sm={4} alignItems='flex-start'>
              <Grid item container spacing={2}>
                <Grid item sm={12}>
                  <DropzoneArea
                    onDrop={handleDrop}
                    image={product.poster}
                    edit={edit}
                    product={product}
                  />
                </Grid>

                <Grid item sm={12}>
                  {showField('language') && (
                    <AutocompleteInput
                      id='language'
                      name='language'
                      label='Language'
                      pageType='product'
                      placeholder='Select the film language'
                      options={langOptions}
                      value={selectedLangValue || null}
                      onChange={(event, newValue) =>
                        handleLangChange(event, newValue ? newValue : '')
                      }
                      auth={true}
                      getOptionLabel={(option) =>
                        option && option.name ? option.name : ''
                      }
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      required={true}
                    />
                  )}
                </Grid>

                <Grid item sm={12}>
                  {showField('regions') && (
                    <AutocompleteInputSelects
                      id='regions'
                      label='Regions'
                      name='regions'
                      pageType='product'
                      value={edit === false ? selectedRegions : product.regions}
                      options={regionsList}
                      placeholder='Select Regions'
                      disabled={false}
                      auth={true}
                      required={true}
                      getOptionLabel={(option) => option}
                      getOptionSelected={(option, value) => option === value}
                      onChange={(event, newValue, reason) =>
                        handleRegionChange(
                          event,
                          newValue ? newValue : '',
                          reason
                        )
                      }
                      selectedOptions={product.regions || []}
                    />
                  )}
                </Grid>

                <Grid item sm={12}>
                  {showField('ISAN') && (
                    <TextInput
                      name='ISAN'
                      type='text'
                      pageType='product'
                      label='ISAN code'
                      placeholder='Enter the ISAN code'
                      value={product.ISAN ? product.ISAN : ''}
                      onChange={handleChange}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            {/*RIGHT*/}
            <Grid item container sm={8} alignItems='flex-start'>
              <Grid item container spacing={2}>
                <Grid item sm={6}>
                  <TextInput
                    name='title'
                    type='text'
                    pageType='product'
                    label='Title *'
                    placeholder='Enter the film title'
                    value={product.title}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item sm={6}>
                  <div className={classes.publishDiv}>
                    <FormLabel
                      className={classes.labelBlack}
                      component='legend'
                    >
                      Publish Film
                    </FormLabel>
                    <Stack
                      direction='row'
                      spacing={1}
                      alignItems='center'
                      style={{ paddingTop: '15px' }}
                    >
                      <AntSwitch
                        checked={product.online ? product.online : false}
                        // onChange={(event) => setPublish(event.target.checked)}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'ant design' }}
                      />
                      {product.online === true ? (
                        <p className={classes.switchText}>Yes</p>
                      ) : (
                        <p className={classes.switchText}>No</p>
                      )}
                    </Stack>
                  </div>
                </Grid>

                <Grid item sm={4}>
                  <TextInput
                    name='director'
                    type='text'
                    pageType='product'
                    label='Director'
                    placeholder='Enter the director name'
                    value={product.director ? product.director : ''}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextInput
                    name='year'
                    type='number'
                    label='Year'
                    pageType='product'
                    placeholder='Enter the film year'
                    value={product.year ? product.year.toString() : ''}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item sm={4}>
                  <TextInput
                    name='duration'
                    type='number'
                    pageType='product'
                    label='Duration (min)'
                    placeholder='Enter the duration'
                    value={product.duration ? product.duration.toString() : ''}
                    onChange={handleChange}
                  />
                </Grid>

                {showField('contentRating') && (
                  <Grid item sm={4}>
                    <AutocompleteInput
                      id='contentRating'
                      name='contentRating'
                      label='Content rating'
                      pageType='product'
                      placeholder='Select the content rating'
                      options={ratingOptions}
                      value={selectedRatingValue || null}
                      onChange={(event, newValue) =>
                        handleRatingChange(event, newValue ? newValue : '')
                      }
                      auth={true}
                      getOptionLabel={(option) =>
                        option && option.code ? option.code : ''
                      }
                      getOptionSelected={(option, value) =>
                        option.code === value.code
                      }
                      required={true}
                    />
                  </Grid>
                )}

                <Grid item sm={12}>
                  <AutocompleteInputSelects
                    id='collections'
                    label='Collections'
                    name='collections'
                    pageType='product'
                    value={selectedCollection}
                    options={collections}
                    placeholder='Select collections to add'
                    disabled={false}
                    auth={true}
                    required={true}
                    getOptionLabel={(option) =>
                      option.name ? option.name : option.title
                    }
                    getOptionSelected={(option, value) =>
                      option.id === value.id || option.id === value._id
                    }
                    onChange={handleCollectionSelectVal}
                    selectedOptions={selectedCollection}
                  />
                </Grid>

                <Grid item sm={12}>
                  <AutocompleteInputSelects
                    id='genres'
                    label='Themes'
                    name='genres'
                    pageType='product'
                    value={edit === false ? selectedGenre : product.genres}
                    options={genreList}
                    placeholder='Select themes'
                    disabled={false}
                    auth={true}
                    required={true}
                    getOptionLabel={(option) => option}
                    getOptionSelected={(option, value) => option === value}
                    onChange={(event, newValue, reason) =>
                      handleGenresChange(
                        event,
                        newValue ? newValue : '',
                        reason
                      )
                    }
                    selectedOptions={product.genres || []}
                  />
                </Grid>

                <Grid item sm={12}>
                  <TextArea
                    name='synopsis'
                    type='text'
                    label='Synopsis'
                    pageType='product'
                    placeholder='Enter the description of the film'
                    value={product.synopsis ? product.synopsis : ''}
                    onChange={handleChange}
                    multiline={true}
                    rows={5}
                  />
                </Grid>
              </Grid>
            </Grid>

            {showField('credits') && (
              <Grid item sm={12}>
                <TextArea
                  name='creditRights'
                  type='text'
                  pageType='product'
                  label='Credit Rights'
                  placeholder='Enter the credit rights'
                  value={product.creditRights ? product.creditRights : ''}
                  onChange={handleChange}
                  multiline={true}
                  rows={2}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default FilmDetails;
