// import moment from 'moment';
/*import {
     commercialRightsDB,
    nonCommercialRightsDB
} from 'database/rightsDB'*/

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Loading from 'components/loading/Loading';
import lListStyle from 'pages/licenses/styles/LListStyles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';
import { isSeller } from 'services/authService';
import {
  FinalizeLicense,
  getPendingLicenses,
} from 'services/educationalLicenseService';
import dashboardStyle from 'styles/dashboard';

const PLlist: React.FC<{ user: any }> = ({ user }) => {
  const [licenses, setLicenses] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const dashboardClasses = dashboardStyle();
  const lListClasses = lListStyle();

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        const value = await getPendingLicenses();
        setLicenses(value);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchLicenses();
  }, []);

  const finalizeLicense = async (licenseID: string) => {
    try {
      await FinalizeLicense(licenseID);
      history.push('/myCustomLicenses');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={dashboardClasses.headerContainer}>
        <Typography className={dashboardClasses.dashboardTitle} variant='h4'>
          My Pending Licenses
        </Typography>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <TableContainer
          className={lListClasses.tableContainer}
          component={Paper}
        >
          <Table className={lListClasses.table}>
            <TableHead className={lListClasses.tableHead}>
              <TableRow>
                <TableCell>Film Title</TableCell>
                <TableCell>Licensee Organization</TableCell>
                <TableCell>Licensee Name</TableCell>
                <TableCell>Licensee Email</TableCell>
                <TableCell>{isSeller() ? 'Options' : 'State'}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={lListClasses.tableBody}>
              {licenses.length === 0 ? (
                <TableRow>
                  <TableCell className={lListClasses.noLicenses} colSpan={5}>
                    No entries were found.
                  </TableCell>
                </TableRow>
              ) : (
                licenses.map((row: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <NavLink
                        className={lListClasses.downloadLink}
                        to={`/movies/${row.TitleID}`}
                      >
                        {row.FilmTitle}
                      </NavLink>
                    </TableCell>
                    <TableCell>{row.Licensee}</TableCell>
                    <TableCell>{row.LicenseeAuthorised}</TableCell>
                    <TableCell>{row.LicenseeEmail}</TableCell>
                    <TableCell>
                      {isSeller() ? (
                        <Button
                          className={dashboardClasses.dashboardButton}
                          variant='outlined'
                          onClick={() => finalizeLicense(row.LicenseID)}
                        >
                          Finalize License
                        </Button>
                      ) : (
                        <Typography className={lListClasses.licenseState}>
                          Pending Approval...
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PLlist);
