import React from 'react';

import { project } from 'project';

import CLlistMFHWrapper from './CLlistMFH';
import CLlistOthersWrapper from './CLlistOthers';

interface Props {
  classes: any;
  history: any;
}

const CLlist: React.FC<Props> = () => {
  return (
    <>{project === 'mfh' ? <CLlistMFHWrapper /> : <CLlistOthersWrapper />}</>
  );
};

export default CLlist;
