import CloseIcon from '@mui/icons-material/Close';
import { FormLabel, Grid, IconButton } from '@mui/material';

import React, { useEffect, useState } from 'react';
import dashboardStyle from 'styles/dashboard';

import SubtitlesDropzone from 'components/controls/dropzone/SubtitlesDropzone';
import { ProductVersionForm } from 'models/ProductsModel';
import { languages_list } from 'utils/utils';

import AutocompleteInput from 'components/controls/autocompleteInput/AutocompleteInput';
import { BaseProduct } from 'mapfilm-api';
import { PRIMARY_COLOR } from 'styles/colors';

interface SubtitlesFormProps {
  product: BaseProduct;
  formData: ProductVersionForm;
  index: number;
  classes?: any;
  handleInputChange: any;
  handleDropChange: any;
  handleFilmVersionAdd: (index: number, uuid: string) => void;
  handleRemoveForm: any;
}

const SubtitlesForm: React.FC<SubtitlesFormProps> = ({
  formData,
  index,
  handleInputChange,
  handleDropChange,
  handleRemoveForm,
}) => {
  const classes = dashboardStyle();
  const [selectedLangValue, setSelectedLangValue] = useState<any>(null);

  useEffect(() => {
    if (formData.language) {
      const foundLang = languages_list.find(
        (lang) => lang.code === formData.language
      );
      setSelectedLangValue(foundLang || null);
    }
  }, [formData.language]);

  const handleLangChange = (event: any, newValue: any) => {
    setSelectedLangValue(newValue);
    handleInputChange(index, 'language', newValue ? newValue.code : '');
  };

  const disablePreviousFiles =
    typeof formData.files === 'string'
      ? formData.files === ''
        ? false
        : true
      : true;

  return (
    <div key={index}>
      <div className={classes.addSubtitlesBody}>
        <div className={classes.iconContainerSub}>
          <IconButton
            sx={{ zIndex: '2' }}
            onClick={() => handleRemoveForm(index, formData)}
          >
            <CloseIcon sx={{ color: PRIMARY_COLOR }} />
          </IconButton>
        </div>

        <Grid container>
          <Grid item xs={6} className={classes.firstColumn}>
            <FormLabel component='legend' className={classes.labelBlack}>
              Subtitle Language
            </FormLabel>
          </Grid>
          <Grid item xs={6} className={classes.secColumn}>
            <FormLabel component='legend' className={classes.labelBlack}>
              Choose Subtitle File
            </FormLabel>
          </Grid>
        </Grid>

        <Grid item xs={12} container className={classes.inputRow}>
          <Grid item xs={6}>
            <AutocompleteInput
              id='language'
              name='language'
              label=''
              pageType='product'
              placeholder='Select subtitle language'
              options={languages_list}
              value={selectedLangValue || null}
              onChange={(event, newValue) =>
                handleLangChange(event, newValue ? newValue : '')
              }
              auth={true}
              getOptionLabel={(option) =>
                option && option.name ? option.name : ''
              }
              getOptionSelected={(option, value) => option.name === value.name}
              required={true}
              disabled={disablePreviousFiles}
            />
          </Grid>

          <Grid item xs={6} className={classes.secColumn}>
            <SubtitlesDropzone
              setPropFile={(acceptedFiles) =>
                handleDropChange(index, acceptedFiles)
              }
              propFile={formData.files}
              disable={disablePreviousFiles}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SubtitlesForm;
