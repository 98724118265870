import CloseIcon from '@mui/icons-material/Close';
import { FormLabel, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DropzoneVideos from 'components/controls/dropzone/VideoDropzone';
import React, { useState } from 'react';
import { useMutation } from 'react-query';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import { ProductVersionForm } from 'models/ProductsModel';
import { createProductMedia } from 'services/productService';
import dashboardStyle from 'styles/dashboard';

import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from 'assets/icons/checkIcon.svg';
import ErrorIcon from 'assets/icons/errorIcon.svg';

// import { UploadMediaButton } from 'components/button/UploadMediaBtn';
import { BaseProduct } from 'mapfilm-api';
import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';

interface MediaFormProps {
  product: BaseProduct;
  productMediaSubtitles: any;
  formData: ProductVersionForm;
  index: number;
  classes?: any;
  handleInputChange: any;
  handleDropChange: any;
  handleFilmVersionAdd: (index: number, uuid: string) => void;
  handleRemoveForm: any;
  edit: boolean;
}

const MediaVODForm: React.FC<MediaFormProps> = ({
  formData,
  product,
  productMediaSubtitles,
  index,
  handleDropChange,
  handleFilmVersionAdd,
  handleRemoveForm,
  edit,
}) => {
  const classes = dashboardStyle();

  const [automaticSubtitles, setAutomaticSubtitles] = useState<boolean>(false);
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutomaticSubtitles(event.target.checked);
  };

  const mutation = useMutation(
    async (data: ProductVersionForm) => {
      if (!data.video) {
        throw new Error('No file');
      }

      const subtitleFiles =
        productMediaSubtitles?.flatMap((sub: any) => sub.files) ?? [];

      const subtitleLanguages =
        productMediaSubtitles?.map((sub: any) => sub.language).join(',') ?? '';

      if (subtitleFiles.length > 0) {
        return createProductMedia(
          product.title || 'Film',
          data.video,
          subtitleFiles,
          subtitleLanguages
        );
      } else {
        return createProductMedia(
          product.title ? product.title : 'Film',
          data.video
        );
      }
    },
    { mutationKey: 'createProductMedia' }
  );

  React.useEffect(() => {
    if (mutation.isSuccess) {
      const uuid = mutation.data;
      handleFilmVersionAdd(index, uuid);
    }
  }, [mutation.isSuccess, mutation.data, index]);

  const handleUploadClick = () => {
    mutation.mutate(formData);
  };

  return (
    <div key={index}>
      <div className={classes.divBtnUploadMedia}>
        <button
          className={
            !formData.video
              ? classes.disButtonUploadVid
              : classes.buttonUploadVid
          }
          type='submit'
          onClick={handleUploadClick}
          disabled={
            !formData.video ||
            formData.vizziID !== undefined ||
            mutation.isLoading ||
            formData.status === 'UPLOADING'
          }
        >
          {mutation.isLoading ||
          (formData.status === 'UPLOADING' &&
            formData.vizziID === undefined) ? (
            <Box sx={{ display: 'inline' }}>
              <CircularProgress
                sx={{
                  color: THIRD_COLOR,
                  width: '25px !important',
                  height: '25px !important',
                }}
              />
            </Box>
          ) : mutation.isError ? (
            <img src={ErrorIcon} />
          ) : formData.vizziID ? (
            <img src={CheckIcon} />
          ) : (
            <>
              Upload Media
              <IconButton
                sx={{
                  zIndex: '1',
                }}
                disabled={!formData.video || formData.version === ''}
              >
                <PublishOutlinedIcon sx={{ color: THIRD_COLOR }} />
              </IconButton>
            </>
          )}
        </button>
      </div>
      <div className={classes.addMediaBody}>
        <div className={classes.iconContainer}>
          {edit ? (
            <div style={{ marginBottom: '20px' }}></div>
          ) : (
            <IconButton
              sx={{
                zIndex: '2',
              }}
              onClick={() => handleRemoveForm(index)}
            >
              <CloseIcon sx={{ color: PRIMARY_COLOR }} />
            </IconButton>
          )}
        </div>
        <Grid container>
          {/* Row 1 */}
          <Grid item xs={12} container className={classes.labelRow}>
            <Grid
              item
              xs={12}
              className={classes.secColumn}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <FormLabel className={classes.labelBlack} component='legend'>
                Choose Video
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ '&.Mui-checked': { color: '#A482BB !important' } }}
                      checked={automaticSubtitles}
                      onChange={handleCheckboxChange}
                      disabled
                    />
                  }
                  label='Apply AI Generated Subtitles'
                />
              </FormGroup>
            </Grid>
          </Grid>

          {/* Row 2 */}
          <Grid item xs={12} container className={classes.inputRow}>
            <Grid item xs={12} className={classes.secColumn}>
              <DropzoneVideos
                setPropVideo={(acceptedFiles) =>
                  handleDropChange(index, 'video', acceptedFiles)
                }
                propVideo={formData.video}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MediaVODForm;
