import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { PRIMARY_COLOR } from 'styles/colors';

const dropzoneStyle = makeStyles<Theme>((theme) => ({
  dropzoneContainer: {
    width: '100%',
    //minHeight: "200px",
    minHeight: '304px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 'none',
    borderRadius: '10px',
    transition: 'border .24s ease-in-out',
    backgroundColor: `${PRIMARY_COLOR} !important`,
  },
  imgSize: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  centralize: {
    textAlign: 'center',
  },
  reactPlayerContainer: {
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: '25px',
    borderRadius: '5px',
  },
  labelsContainer: {
    width: '100%',
    minHeight: '50px',
    // minHeight: '100px',
    //height: "304px",
    // marginTop: '25px',
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    backgroundColor: 'white',
    borderRadius: '5px',
  },
  disabledContainer: {
    width: '100%',
    minHeight: '50px',
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E0E0E0',
    justifyContent: 'center',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    backgroundColor: 'white',
    borderRadius: '5px',
    pointerEvents: 'none',
    cursor: 'not-allowed',
    opacity: 0.5,
  },
  pContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  pContainerSub: {
    display: 'flex',
    overflow: 'auto',
  },
  pStyle: {
    textAlign: 'center',
    color: '#7A7A7A',
    fontFamily: 'Roboto',
    fontSize: 16,
    marginLeft: '13px',
  },
}));

export default dropzoneStyle;
