import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';

const useStyles = makeStyles<Theme>((theme) => ({
  inputContainer: {
    display: 'flex',
    width: '100%',
    padding: '14px 20px 0 10px !important',
    background: '#fbfbfb',
    fontSize: 16,
    border: '1px solid #E0E0E0',
    color: 'black',
    lineHeight: '30px !important',
    borderRadius: 5,
    transition: 'border 500ms ease-out',
    '&.Mui-error': {
      border: '1px solid red',
    },
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
  },
  inputTxt: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 'unset',
    '&::placeholder': {
      color: '#7A7A7A',
      opacity: 1,
    },
    '&:-webkit-autofill': {
      '-webkit-box-shadow': '0 0 0 30px white inset',
    },
  },
  focused: {
    boxShadow: '2px 2px #ccc !important',
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold !important',
    color: `${THIRD_COLOR} !important`,
    textTransform: 'uppercase',
    paddingBottom: 8,
    '&.Mui-focused': {
      color: `${THIRD_COLOR} !important`,
    },
  },
  labelBlack: {
    fontSize: 16,
    fontWeight: 'bold !important',
    color: `${PRIMARY_COLOR} !important`,
    textTransform: 'uppercase',
    paddingBottom: 8,
    '&.Mui-focused': {
      color: `${PRIMARY_COLOR} !important`,
    },
  },
}));

const TextArea = ({
  id,
  type,
  name,
  value,
  setInputValue,
  placeholder,
  disabled,
  required,
  label,
  error,
  helperText,
  pageType,
  ...props
}: any) => {
  const classes = useStyles();

  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;
  const inputLabelId = label && id ? `${id}-label` : undefined;

  const handleInputChange = (value: any) => {
    if (setInputValue) {
      setInputValue(value);
    }
  };

  return (
    <FormControl className="form-input" error={error} fullWidth>
      <FormLabel
        htmlFor={id}
        id={inputLabelId}
        className={pageType === 'product' ? classes.labelBlack : classes.label}
        component="legend"
      >
        {label}
      </FormLabel>

      <Input
        id={id}
        classes={{
          root: classes.inputContainer,
          input: classes.inputTxt,
          focused: classes.focused,
        }}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        disableUnderline
        fullWidth
        disabled={disabled}
        required={required ? true : false}
        onChange={handleInputChange}
        {...props}
      />

      {helperText && <FormHelperText id={helperTextId}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default TextArea;