import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';

import { project } from 'project';

import { UserActions } from 'redux/actions';
import { isLoggedIn } from 'services/authService';
import { getUser } from 'services/userService';
import Dropdown from 'templates/dropdown/Dropdown';
import headerStyle from './HeaderStyles';

import LogoScene from 'assets/logo/logo-scene.png';
import LogoMap from 'assets/logo/logo-white.svg';
import LogoMFH from 'assets/logo/mfh-logo.png';

interface OwnProps {
  history: any;
  open: boolean;
  handleDrawerOpen: (payload: any) => void;
}

const Header: React.FC<OwnProps> = ({ history, open }) => {
  const classes = headerStyle();
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchUser() {
      try {
        const value = await getUser();
        dispatch(UserActions.updateUser(value));
      } catch (error) {
        localStorage.clear();
        history.push(`/auth/login`);
      }
    }
    fetchUser();
  }, [dispatch]);

  if (!isLoggedIn()) {
    return <Redirect to='/auth/login' />;
  }

  const getLogoSrc = (project: string) => {
    switch (project) {
      case 'scene':
        return { src: LogoScene, additionalClass: classes.logoImgScene };
      case 'map':
        return { src: LogoMap, additionalClass: '' };
      case 'mfh':
        return { src: LogoMFH, additionalClass: classes.logoImgMfh };
      default:
        return { src: LogoMap, additionalClass: '' };
    }
  };

  const logo = getLogoSrc(project);

  return (
    <AppBar position='absolute' className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.categoryDropdown}>
          <NavLink to={project === 'scene' ? '/collections' : '/catalogue'}>
            <img className={`${classes.logoImg} ${logo.additionalClass}`} src={logo.src} alt='Logo' />
          </NavLink>
        </Box>
        <Dropdown />
      </Toolbar>
    </AppBar>
  );
};

export default Header;

