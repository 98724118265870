import React from 'react';

import InfoRoundedIcon from '@mui/icons-material/Info';
import pnfStyle from 'pages/pagenotfound/PageNotFoundStyles';

interface Props {
  classes?: any;
  history: any;
  location: any;
}

const PageNotFound: React.FC<Props> = () => {
  const pageClasses = pnfStyle();
  return (
    <div className={pageClasses.pnfContent}>
      <InfoRoundedIcon className={pageClasses.pnfIcon} />

      <span className={pageClasses.pnfTxt}>404 PAGE NOT FOUND</span>
    </div>
  );
};

export default PageNotFound;
