import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { HOVER_COLOR, SECONDARY_COLOR, THIRD_COLOR } from 'styles/colors';

const licenseStyle = makeStyles<Theme>((theme) => ({
  /* License Rights Title & Options */
  licenseMovieTitle: {
    color: '#7A7A7A !important',
    marginTop: '10px !important',
    marginBottom: '40px !important',
    fontWeight: 'bold !important',
    fontSize: 16,
    textTransform: 'uppercase',
    letterSpacing: '0.02em',
  },
  licenseTitle: {
    color: SECONDARY_COLOR,
    fontSize: 20,
    fontWeight: 'bold',
    letterSpacing: '0.04em',
    marginBottom: 20,
  },
  licenseSubtitle: {
    color: SECONDARY_COLOR,
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: '0.04em',
    marginBottom: 15,
  },
  doneIcon: {
    marginLeft: 5,
    fontSize: 15,
  },
  titleMargin: {
    marginTop: 30,
  },
  optionsContainer: {
    marginBottom: '30px',
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginRight: '35px !important',
    },
  },
  licenseButton: {
    height: 40,
    paddingRight: '20px !important',
    paddingLeft: '20px !important',
    backgroundColor: `${THIRD_COLOR} !important`,
    borderRadius: '10px !important',
    fontWeight: 400,
    fontSize: '16px !important',
    color: '#7A7A7A !important',
    letterSpacing: '0.02em',
    '&:hover': {
      backgroundColor: `${HOVER_COLOR} !important`,
      color: `${THIRD_COLOR} !important`,
    },
    '&.MuiButtonBase-root': {
      textTransform: 'capitalize',
    },
  },
  activeButton: {
    backgroundColor: `${SECONDARY_COLOR} !important`,
    color: `${THIRD_COLOR} !important`,
  },
  savedButton: {
    backgroundColor: `${SECONDARY_COLOR} !important`,
    color: `${THIRD_COLOR} !important`,
  },
  noLicenses: {
    marginTop: 30,
    fontSize: 20,
    fontWeight: 700,
    letterSpacing: 0.5,
    color: '#7A7A7A',
    textAlign: 'center',
  },

  /* License Rights Box */
  licenseRightsBody: {
    position: 'relative',
    padding: 20,
    paddingBottom: 80,
    border: `1px solid ${SECONDARY_COLOR}`,
    borderRadius: 10,
    minHeight: 475,
  },

  /* Territory Box Layout */
  territoryContainer: {
    '& >*': {
      paddingRight: 33,
    },
  },
  territoryItemGrid: {
    marginTop: 20,
  },
  territoryItem: {
    backgroundColor: THIRD_COLOR,
    borderRadius: 10,
    minWidth: 206,
    paddingTop: 25,
    paddingLeft: 25,
    paddingBottom: 25,
    minHeight: 350,
    display: 'flex',
    flexDirection: 'column',
  },

  /* Territory Box Content */
  territoryTitle: {
    fontSize: 16,
    fontWeight: 700,
    color: SECONDARY_COLOR,
    textTransform: 'uppercase',
    lineHeight: '19px',
    paddingBottom: 8,
  },
  closeContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 5,
  },
  territoryOptionsContainer: {
    flex: 1,
    paddingRight: 15,
  },
  territoryOptions: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'column',
  },
  exclusivityContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  blockedPeriodsContainer: {
    marginTop: 8,
  },
  territoryLabel: {
    color: '#7A7A7A',
    fontSize: 14,
    letterSpacing: '0.02em',
    height: 35,
  },
  plusIcon: {
    fontSize: 22,
  },
  checkboxIcon: {
    '& .MuiSvgIcon-root': {
      fontSize: 22,
    },
  },
  blockedPeriodStyle: {
    '& .rs-picker-toggle.active': {
      borderColor: SECONDARY_COLOR,
    },
    '& .rs-picker-toggle-placeholder': {
      color: '#7A7A7A',
    },
    '& .rs-picker-toggle-caret': {
      color: SECONDARY_COLOR,
    },
    '& .rs-picker-toggle-value': {
      color: `${SECONDARY_COLOR} !important`,
      fontWeight: 'bold',
    },
  },
  periodRemoveStyle: {
    color: SECONDARY_COLOR,
  },

  /* Save Button */
  saveBtn: {
    width: 123,
    right: '25px !important',
    bottom: '25px !important',
    backgroundColor: `${SECONDARY_COLOR} !important`,
    color: 'white !important',
    '&.Mui-disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.3) !important',
      color: 'white !important',
    },
    '&.MuiButtonBase-root': {
      position: 'absolute',
    },
  },

  /* Educational Window */
  educationalContainer: {
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  eduElementView: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  labelContainer: {
    marginBottom: 25,
  },
  licenseLabel: {
    display: 'block',
    fontSize: 16,
    fontWeight: 700,
    color: SECONDARY_COLOR,
    textTransform: 'uppercase',
    lineHeight: '19px',
    paddingBottom: 8,
  },
  licenseDescription: {
    fontSize: 16,
    color: 'black',
    alignItems: 'center',
  },
  eduElement: {
    padding: 15,
  },
  eduRightFormControl: {
    width: '70%',
    minWidth: '18em',
    maxWidth: '25em',
  },
  eduDatePicker: {
    width: '100%',
    '&.rs-picker-disabled': {
      opacity: 1,
      '& .rs-picker-toggle-value': {
        color: 'rgba(0, 0, 0, 0.5) !important',
      },
      '& .rs-picker-toggle.active': {
        boxShadow: 'none',
      },
    },
    '& .rs-picker-toggle': {
      display: 'flex',
      background: THIRD_COLOR,
      fontSize: 16,
      height: 48,
      padding: '0 20px',
      border: 0,
      '&:hover': {
        border: '0 !important',
      },
      '&.active': {
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), ' +
          '0px 1px 1px 0px rgb(0 0 0 / 14%), ' +
          '0px 1px 3px 0px rgb(0 0 0 / 12%)',
      },
    },
    '& .rs-picker-toggle-value': {
      display: 'flex',
      alignItems: 'center',
      color: 'black !important',
    },
    '& .rs-picker-toggle-caret': {
      top: '12px !important',
      right: '18px !important',
    },
    '& .rs-picker-toggle-clean': {
      top: '12px !important',
      right: '40px !important',
    },
  },
}));

export default licenseStyle;
