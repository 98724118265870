import React, { useState } from 'react';

import { isLoggedIn } from 'services/authService';
import Header from 'templates/header/Header';
import SideBar from 'templates/sidebar/Sidebar';

import stylesStyle from 'styles/styles';

interface OwnProps {
  history: any;
  children: React.ReactElement;
}

const MainLayout: React.FC<OwnProps> = ({ history, children }) => {
  const classes = stylesStyle();
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  return (
    <div className={classes.root}>
      <Header open={open} handleDrawerOpen={handleDrawerOpen} history={history} />

      {isLoggedIn() && <SideBar open={open} handleDrawerClose={handleDrawerClose} />}

      <main className={classes.layoutContent}>
        <div className={classes.layoutContainer}>{children}</div>
      </main>
    </div>
  );
};

export default MainLayout;