import {
  BaseProduct,
  GetCategoriesResponse,
  LibraryProduct,
  LibraryProductVersion,
  UpdateProductModel,
} from 'mapfilm-api';
import { api } from 'services/customAxios';

export function logFormData(formData: FormData) {
  formData.forEach((value, key) => {
    console.log(key, value);
  });
}

export function objectToFormData(
  obj: { [key: string]: any },
  formData = new FormData(),
  parentKey = ''
): FormData {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const formattedKey = parentKey ? `${parentKey}[${key}]` : key;

      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          const formattedArrayKey = `${formattedKey}[${index}]`;
          formData.append(formattedArrayKey, item);
        });
      } else if (typeof value === 'object' && value !== null) {
        objectToFormData(value, formData, formattedKey);
      } else {
        formData.append(formattedKey, value);
      }
    }
  }

  return formData;
}

const createProduct = async (
  product: BaseProduct,
  poster: File | undefined
) => {
  try {
    //TODO add type to response (needs to be added to mapfilm-api)

    let body: FormData | BaseProduct;
    if (poster) {
      body = objectToFormData(product);
      body.append('file', poster);
      console.log('Form Data:');
      logFormData(body);
    } else {
      body = product;
    }

    const response = await api.post('/library/product', body);
    return response.data;
  } catch (error: any) {
    console.log(error.response);
    console.log('message: ' + error.message);
    throw error;
  }
};

const updateProduct = async (
  product: UpdateProductModel,
  poster: File | undefined,
  productId: string
) => {
  try {
    let body: FormData | UpdateProductModel;
    if (poster) {
      body = objectToFormData(product);
      body.append('file', poster);
      console.log('Form Data:');
      logFormData(body);
    } else {
      body = product;
    }
    const response = await api.put(`/library/product/${productId}`, body);
    return response.data;
  } catch (error: any) {
    console.log(error.response);
    console.log('message: ' + error.message);
    throw error;
  }
};

const getProduct = async (productId: string, isBuyer: boolean) => {
  try {
    const url = isBuyer ? '/catalog/product' : '/library/product';
    const response = await api.get<LibraryProduct>(`${url}/${productId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getAllProducts = async (isSeller: boolean) => {
  try {
    const url = isSeller ? '/library/product' : '/catalog/product';
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const requestDownload = async (id: string) => {
  try {
    const response = await api.post(`/catalog/product/download/${id}`);
    return response.data;
  } catch (error: any) {
    console.log(error);

    const customError = {
      message: error?.response?.data?.message || error?.message,
      data: error?.response?.data || error,
    };

    throw customError;
  }
};

const getIDsDownload = async () => {
  try {
    const response = await api.get(`/catalog/product/download/ids`);
    return response.data;
  } catch (error: any) {
    console.log(error);

    const customError = {
      message: error?.response?.data?.message || error?.message,
      data: error?.response?.data || error,
    };

    throw customError;
  }
};

const getDownloadsList = async () => {
  try {
    const response = await api.get(`catalog/product/download/list`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const downloadFilm = async (id: string, versionId: string) => {
  try {
    const response = await api.get(
      `/catalog/product/version/download/${id}/${versionId}`,
      {
        responseType: 'blob',
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const checkDownloads = async () => {
  try {
    const response = await api.get(`/catalog/product/download/check`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const resetDownloadsNumber = async () => {
  try {
    const response = await api.post('/admin/downloads');
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getresetDownloadsLimits = async () => {
  try {
    const response = await api.get('/admin/downloads');
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const resetCustomDownloadsNumber = async (
  numberDownloads: number,
  resetDate: Date
) => {
  try {
    const response = await api.put('/admin/downloads', {
      downloadLimit: numberDownloads,
      limitStartDate: resetDate,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCollectionProducts = async (collectionId: string) => {
  try {
    const response = await api.get(`/library/product/${collectionId}`);
    return response.data.products;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getProductCategories = async (): Promise<GetCategoriesResponse> => {
  try {
    const response = await api.get<GetCategoriesResponse>(
      '/catalog/categories'
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const createProductMedia = async (
  name: string,
  file: File,
  files?: File[],
  language?: string
): Promise<string> => {
  try {
    const uploadForm = new FormData();
    uploadForm.append('version', name);
    uploadForm.append('files', file);
    if (files && files.length > 0 && language) {
      files.forEach((fileItem) => {
        uploadForm.append('files', fileItem);
      });
      uploadForm.append('language', language);
    }
    const response = await api.post<{
      uuid: string;
    }>('/library/product/version', uploadForm);
    return response.data.uuid;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateProductMediaSubtitles = async (
  vizziID: string,
  files?: any[],
  language?: string,
  oldProcessedStringFiles?: { language: string; url: string }[]
): Promise<string> => {
  try {
    const uploadForm = new FormData();
    if (files && files.length > 0 && language) {
      files.forEach((fileItem) => {
        uploadForm.append('files', fileItem);
      });
      uploadForm.append('language', language);
    }

    if (oldProcessedStringFiles && oldProcessedStringFiles.length >= 0) {
      uploadForm.append(
        'oldProcessedSubtitles',
        JSON.stringify(oldProcessedStringFiles)
      );
    }

    console.log(uploadForm);
    const response = await api.post<{
      vizziID: string;
    }>(`/library/product/version/${vizziID}`, uploadForm);
    return response.data.vizziID;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deleteProductMediaSubtitle = async (
  vizziID: string,
  subtitleToDelete: { language: string; url: string }[]
): Promise<string> => {
  try {
    const response = await api.put<{
      vizziID: string;
    }>(
      `/library/product/version/${vizziID}`,
      { subtitleToDelete },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data.vizziID;
  } catch (error) {
    console.error('Error deleting subtitles:', error);
    throw error;
  }
};

const getProductMedia = async (
  uuids: string[]
): Promise<LibraryProductVersion[]> => {
  try {
    return await api
      .get<LibraryProductVersion[]>('/library/product/version', {
        params: {
          versions: uuids,
        },
      })
      .then((response) => response.data);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const watchProductMedia = async (
  id: string,
  isBuyer: boolean
): Promise<{ url: string; mime: string; poster: string }> => {
  const url = isBuyer
    ? '/catalog/product/version/watch'
    : '/library/product/version/watch';

  const response = await api.get<{ url: string; mime: string; poster: string }>(
    `${url}/${id}`
  );
  return response.data;
};

export {
  checkDownloads,
  createProduct,
  createProductMedia,
  updateProductMediaSubtitles,
  resetDownloadsNumber,
  resetCustomDownloadsNumber,
  getresetDownloadsLimits,
  deleteProductMediaSubtitle,
  downloadFilm,
  getAllProducts,
  getCollectionProducts,
  getDownloadsList,
  getIDsDownload,
  getProduct,
  getProductCategories,
  getProductMedia,
  requestDownload,
  updateProduct,
  watchProductMedia,
};
