import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';

import React, { useState } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
} from 'react-query';
import { useHistory } from 'react-router-dom';

import { modalStyle } from 'components/modal/TermsUsageModalStyle';
import lListStyle from 'pages/licenses/styles/LListStyles';
import dashboardStyle from 'styles/dashboard';

import { convertHtmlToReact } from '@hedgedoc/html-to-react';

import Loading from 'components/loading/Loading';
import {
  getAllBespokeLicenses,
  postSignLicense,
} from 'services/educationalLicenseService';

interface SignLicenseProps {
  classes?: any;
  refetchLicenseStatus: any;
}

const queryClient = new QueryClient();

const SignLicenseWrapper: React.FC<SignLicenseProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <SignLicense {...props} />
    </QueryClientProvider>
  );
};

const SignLicense: React.FC<SignLicenseProps> = ({ refetchLicenseStatus }) => {
  const dashboardClasses = dashboardStyle();
  const lListClasses = lListStyle();

  const [agree, setAgree] = useState(false);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
  const history = useHistory();

  const {
    data: templates,
    isLoading,
    isError,
    error,
  } = useQuery(['bespokeLicenses'], getAllBespokeLicenses);

  const signLicenseMutation = useMutation(postSignLicense, {
    onSuccess: () => {
      refetchLicenseStatus().then(() => {
        history.push('/catalogue');
      });
    },
    onError: (err) => {
      console.error('Error signing license:', err);
    },
  });

  const handleClose = () => {
    if (agree) {
      signLicenseMutation.mutate();
    }
  };

  const handleAgreeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgree(event.target.checked);
  };

  const handleTemplateChange = (index: number) => {
    setSelectedTemplateIndex(index);
  };

  if (isLoading) return <Loading />;

  return (
    <div>
      <div className={dashboardClasses.headerContainer}>
        <Typography
          className={dashboardClasses.dashboardTitle}
          variant='h4'
          fontWeight={700}
        >
          Sign License Agreements
        </Typography>
      </div>
      <Box sx={modalStyle}>
        <Typography className={lListClasses.descriptionText} variant='body1'>
          Please read carefully before agreeing. Ensure you understand all terms
          and conditions under which our project partners have granted access.
        </Typography>
        {templates && templates.length > 1 && (
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
            {templates.map((template: any, index: number) => (
              <Button
                key={template.id}
                variant={
                  selectedTemplateIndex === index ? 'contained' : 'outlined'
                }
                className={
                  selectedTemplateIndex === index
                    ? lListClasses.buttonStyle
                    : lListClasses.buttonStyleContracts
                }
                onClick={() => handleTemplateChange(index)}
                sx={{ mx: 1 }}
              >
                {template.title}
              </Button>
            ))}
          </Box>
        )}
        <Box
          sx={{
            position: 'relative',
          }}
        >
          {templates && templates.length > 0 && (
            <div className={lListClasses.htmlViewerStyle}>
              {convertHtmlToReact(templates[selectedTemplateIndex]?.preview)}
            </div>
          )}
        </Box>
        <Box sx={{ mt: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agree}
                className={lListClasses.checkboxStyle}
                onChange={handleAgreeChange}
              />
            }
            label='I agree with all the license agreements and terms and conditions when using the Archive Material in your film.'
          />
        </Box>
        <Box sx={{ mt: 2, textAlign: 'right' }}>
          <Button
            variant='contained'
            className={lListClasses.buttonStyle}
            onClick={handleClose}
            disabled={!agree || signLicenseMutation.isLoading}
          >
            {signLicenseMutation.isLoading
              ? 'Submitting...'
              : 'Agree and Close'}
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default SignLicenseWrapper;
