import { Box, Grid, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ExclusivityComponent from 'components/licenses/forms/fields/ExclusivityComponent';
import PeriodsComponent from 'components/licenses/forms/windows/PeriodsComponent';
import {
  CommercialRightModel,
  RightWindowModel,
} from 'models/LicenseWindowModel';
import {
  CustomLicenseActions,
  LicenseTemplateActions,
  LicenseWindowActions,
} from 'redux/actions';
import { RootState } from 'redux/store';

import CloseIcon from '@mui/icons-material/Close';
import AutocompleteInput from 'components/controls/autocompleteInput/AutocompleteInput';

interface OwnProps {
  classes?: any;
  rightName: string;
  subRightName: string;
  clauseName: string;
  licenseType: string;
  operationMode: string;
  territoryId: string;
}

interface DispatchProps {
  removeTerritoryEntryLW: (payload: any) => void;
  updateTerritoryLW: (payload: any) => void;
  removeTerritoryLW: (payload: any) => void;
  removeTerritoryEntryLT: (payload: any) => void;
  updateTerritoryLT: (payload: any) => void;
  removeTerritoryEntryCL: (payload: any) => void;
  updateTerritoryCL: (payload: any) => void;
}

interface StateProps {
  clauseWindow: any;
  territoriesTemplate: any;
  customTerritories: any;
  templateTerritory: any;
  customTerritory: any;
}

type Props = StateProps & DispatchProps & OwnProps;

const TerritoryWindow: React.FC<Props> = (props) => {
  const {
    classes,
    operationMode,
    territoriesTemplate,
    licenseType,
    customTerritories,
    templateTerritory,
    rightName,
    subRightName,
    clauseName,
    territoryId,
    customTerritory,
    clauseWindow,
    removeTerritoryEntryLW,
    removeTerritoryEntryLT,
    removeTerritoryEntryCL,
    updateTerritoryLT,
    updateTerritoryCL,
    removeTerritoryLW,
    updateTerritoryLW,
  } = props;

  const wasTerritorySelected = (territoryId: string) => {
    let territory = '';

    if (licenseType === 'template')
      territory = territoriesTemplate.find(
        (element: any) => element.territoryId === territoryId
      ).territoryName;
    else if (licenseType === 'custom') {
      territory = customTerritories.find(
        (element: any) => element.territoryId === territoryId
      ).territoryName;
    }

    if (
      territory === '' ||
      typeof territory === 'undefined' ||
      territory === null
    )
      return 'hidden';
    else return 'visible';
  };

  const getTerritory = (territoryId: string) => {
    if (licenseType === 'template') {
      const territoryTemplate = territoriesTemplate.find(
        (element: any) => element.territoryId === territoryId
      );

      return territoryTemplate?.territoryName;
    } else if (licenseType === 'custom') {
      const territoryCustom = customTerritories.find(
        (element: any) => element.territoryId === territoryId
      );

      return territoryCustom?.territoryName;
    } else return '';
  };

  const getExclusivity = () => {
    if (licenseType === 'template') {
      if (
        templateTerritory.exclusivityAllowed &&
        templateTerritory.nonExclusivityAllowed
      )
        return 'Exclusivity and Non-Exclusivity Allowed';
      else if (templateTerritory.exclusivityAllowed)
        return 'Exclusivity Allowed';
      else if (templateTerritory.nonExclusivityAllowed)
        return 'Non-Exclusivity Allowed';
    } else if (licenseType === 'custom') {
      if (templateTerritory.exclusivity) return 'Exclusive';
      else if (templateTerritory.nonExclusivityAllowed) return 'Non-Exclusive';
    }
  };

  const RemoveTerritoryComponent = () => {
    const handleOnCLick = () => {
      removeTerritoryEntryLW({
        rightName: rightName,
        subRightName: subRightName,
        clauseName: clauseName,
        territoryId: territoryId,
      });

      if (operationMode === 'create') {
        if (licenseType === 'template')
          removeTerritoryEntryLT({
            rightName: rightName,
            subRightName: subRightName,
            clauseName: clauseName,
            territoryId: territoryId,
            licenseType: licenseType,
            operationMode: operationMode,
          });
        if (licenseType === 'custom')
          removeTerritoryEntryCL({
            rightName: rightName,
            subRightName: subRightName,
            clauseName: clauseName,
            territoryId: territoryId,
          });
      }
    };

    return (
      <>
        <div className={classes.closeContainer}>
          <IconButton size='small' onClick={handleOnCLick}>
            <CloseIcon />
          </IconButton>
        </div>
      </>
    );
  };

  const handleTerritoryInputChange = (
    newValue: any,
    territoryId: any,
    reason: string
  ) => {
    if (operationMode === 'create') {
      if (licenseType === 'template')
        updateTerritoryLT({
          rightName: rightName,
          subRightName: subRightName,
          clauseName: clauseName,
          territoryId: territoryId,
          territoryName: newValue,
        });
      if (licenseType === 'custom')
        updateTerritoryCL({
          rightName: rightName,
          subRightName: subRightName,
          clauseName: clauseName,
          territoryId: territoryId,
          territoryName: newValue,
        });
    }
    //Event type
    if (reason === 'clear')
      removeTerritoryLW({
        rightName: rightName,
        subRightName: subRightName,
        clauseName: clauseName,
        territoryId: territoryId,
      });
    else
      updateTerritoryLW({
        rightName: rightName,
        subRightName: subRightName,
        clauseName: clauseName,
        territoryId: territoryId,
        territoryName: newValue,
      });
  };

  useEffect(() => {
    if (operationMode === 'view') {
      for (let i = 0; i < territoriesTemplate.length; i++) {
        handleTerritoryInputChange(
          territoriesTemplate[i].territoryName,
          territoriesTemplate[i].territoryId,
          ''
        );
      }
    }
  }, []);

  const territoryValue = getTerritory(territoryId);

  return (
    <Grid item container xs={12} sm={4} className={classes.territoryItemGrid}>
      <Grid item container className={classes.territoryItem}>
        <Grid item container alignItems='flex-start'>
          <Grid container item xs={10} sm={10}>
            {/* Territory */}
            {operationMode === 'create' ? (
              <>
                <AutocompleteInput
                  id={territoryId}
                  label='Territory'
                  pageType=''
                  name='territory'
                  options={clauseWindow.territoriesLeftToBeChosen}
                  onChange={(event, newValue, reason) => {
                    handleTerritoryInputChange(newValue, territoryId, reason);
                  }}
                  getOptionLabel={(option) => option}
                  getOptionSelected={(option, value) => option === value}
                  value={territoryValue}
                  placeholder='Search territory...'
                  auth={false}
                  required={false}
                />
              </>
            ) : (
              <>
                Territory:
                {licenseType === 'template'
                  ? templateTerritory?.territoryName
                  : customTerritory?.territoryName}
              </>
            )}
          </Grid>
          <Grid item xs={2} sm={2}>
            {operationMode === 'create' ? (
              <>
                <RemoveTerritoryComponent />
              </>
            ) : (
              ''
            )}
          </Grid>
        </Grid>

        <Grid item className={classes.territoryOptionsContainer}>
          <Box visibility={wasTerritorySelected(territoryId)}>
            <div className={classes.territoryOptions}>
              {/* Exclusivity */}
              {operationMode === 'create' ? (
                <>
                  <ExclusivityComponent
                    rightName={rightName}
                    subRightName={subRightName}
                    clauseName={clauseName}
                    territoryId={territoryId} // Territory input id
                    territoryName={getTerritory(territoryId)}
                    licenseType={licenseType}
                  />
                </>
              ) : (
                getExclusivity()
              )}

              {
                <PeriodsComponent
                  rightName={rightName}
                  subRightName={subRightName}
                  clauseName={clauseName}
                  territoryId={territoryId}
                  territoryName={getTerritory(territoryId)}
                  licenseType={licenseType}
                  operationMode={operationMode}
                />
              }
            </div>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const rightWindow = state.licenseWindow.openTabs.find(
    (element: RightWindowModel) => element.right === ownProps.rightName
  ) as CommercialRightModel;
  const clauseWindow =
    rightWindow !== undefined
      ? rightWindow[ownProps.subRightName].openTabs.find(
          (element: any) => element.clause === ownProps.clauseName
        )
      : null;

  const territoriesTemplate =
    state.licenseTemplate.commercialRights[ownProps.rightName].subRights[
      ownProps.subRightName
    ].clauses[ownProps.clauseName].territories;
  const customTerritories =
    state.customLicense.commercialRights[ownProps.rightName][
      ownProps.subRightName
    ][ownProps.clauseName].territories;

  let templateTerritory, customTerritory;

  if (ownProps.licenseType === 'template') {
    templateTerritory = territoriesTemplate.find(
      (element: any) => element.territoryId === ownProps.territoryId
    );
  } else if (ownProps.licenseType === 'custom') {
    customTerritory = customTerritories.find(
      (element: any) => element.territoryId === ownProps.territoryId
    );
  }
  return {
    clauseWindow: clauseWindow,
    territoriesTemplate: territoriesTemplate,
    customTerritories: customTerritories,
    templateTerritory: templateTerritory,
    customTerritory: customTerritory,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      removeTerritoryEntryLW: LicenseWindowActions.removeTerritoryEntryLW,
      updateTerritoryLW: LicenseWindowActions.updateTerritoryLW,
      removeTerritoryLW: LicenseWindowActions.removeTerritoryLW,
      /* LicenseTemplate */
      removeTerritoryEntryLT: LicenseTemplateActions.removeTerritoryEntryLT,
      updateTerritoryLT: LicenseTemplateActions.updateTerritoryLT,

      /* CustomLicense */
      removeTerritoryEntryCL: CustomLicenseActions.removeTerritoryEntryCL,
      updateTerritoryCL: CustomLicenseActions.updateTerritoryCL,
    },
    dispatch
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(TerritoryWindow);
