import React from 'react';

import { project } from 'project';

import authStyle from 'styles/auth';
import RegisterPageGeneral from './pages/RegisterPageGeneral';
import RegisterPageMFH from './pages/RegisterPageMFH';
import './RegisterPage.css';

interface Props {
  classes?: any;
  history: any;
}

const RegisterPage: React.FC<Props> = ({ history }) => {
  const classes = authStyle();
  return (
    <>
      {project === 'mfh' ? (
        <RegisterPageMFH classes={classes} history={history} />
      ) : (
        <RegisterPageGeneral classes={classes} history={history} />
      )}
    </>
  );
};

export default RegisterPage;
