import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { Link } from '@mui/material';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import MLogout from 'components/logout/Logout';
import { UserModel } from 'models';
import { UserActions } from 'redux/actions';
import { isBuyer, isSeller, logout } from 'services/authService';

import AssignmentIcon from '@mui/icons-material/Assignment';
import MovieIcon from '@mui/icons-material/Movie';
import PersonIcon from '@mui/icons-material/Person';
import TheatersIcon from '@mui/icons-material/Theaters';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import GroupsIcon from '@mui/icons-material/Groups';

import { project } from 'project';

import { HOVER_COLOR, SECONDARY_COLOR, THIRD_COLOR } from 'styles/colors';

interface DropdownOptionsProps {
  user: UserModel;
  homePage: string;
  blockchain: boolean;
  updateUser: any;
  setToggle: any;
}

const useStyles = makeStyles<Theme>((theme) => ({
  dropdownIcon: {
    fontSize: 24,
    width: 50,
  },
  dropdownItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '15px 25px',
    clear: 'both',
    whiteSpace: 'nowrap',
    textDecoration: 'none !important',
    border: 0,
    color: `${THIRD_COLOR} !important`,
    '&.MuiTypography-root': {
      fontSize: 16,
      fontWeight: 500,
    },
    '&:hover': {
      textDecoration: 'none',
      color: `${SECONDARY_COLOR} !important`,
      backgroundColor: `${THIRD_COLOR} !important`,
      border: 'none',
      outline: 'none',
    },
    '&:hover svg': {
      color: HOVER_COLOR,
    },
    '&:focus': {
      textDecoration: 'none',
      color: `${SECONDARY_COLOR} !important`,
      backgroundColor: `${THIRD_COLOR} !important`,
      border: 'none',
      outline: 'none',
    },
    '&:active': {
      color: `${SECONDARY_COLOR} !important`,
      backgroundColor: `${THIRD_COLOR} !important`,
      border: 'none',
      outline: 'none',
    },
  },
}));

function DropdownOptions(props: DropdownOptionsProps) {
  const classes = useStyles();

  return (
    <>
      {props.user.role !== 'admin' && (
        <>
          <Link
            className='dropdown-item'
            classes={{ root: classes.dropdownItem }}
            onClick={(e: any) => props.setToggle(false)}
            component={NavLink}
            activeClassName='active'
            to={project === 'scene' ? '/collections' : '/catalogue'}
          >
            <MovieIcon classes={{ root: classes.dropdownIcon }} />
            {isSeller()
              ? project === 'scene'
                ? 'Library'
                : 'My Films'
              : 'Films'}
          </Link>

          {isSeller() && project !== 'mfh' && (
            <Link
              className='dropdown-item'
              classes={{ root: classes.dropdownItem }}
              onClick={(e: any) => props.setToggle(false)}
              component={NavLink}
              activeClassName='active'
              to='/myBespokeLicenses'
            >
              <AssignmentTurnedInIcon
                classes={{ root: classes.dropdownIcon }}
              />
              My Bespoke Licenses
            </Link>
          )}

          {project !== 'mfh' && (
            <Link
              className='dropdown-item'
              classes={{ root: classes.dropdownItem }}
              onClick={(e: any) => props.setToggle(false)}
              component={NavLink}
              activeClassName='active'
              to='/myPendingLicenses'
            >
              <AssignmentTurnedInIcon
                classes={{ root: classes.dropdownIcon }}
              />
              My Pending Licenses
            </Link>
          )}

          {props.user.role !== 'user' && (
            <Link
              className='dropdown-item'
              classes={{ root: classes.dropdownItem }}
              onClick={(e: any) => props.setToggle(false)}
              component={NavLink}
              activeClassName='active'
              to='/myCustomLicenses'
            >
              <AssignmentIcon classes={{ root: classes.dropdownIcon }} />
              My Signed Licenses
            </Link>
          )}

          {isBuyer() && project === 'mfh' && (
            <Link
              className='dropdown-item'
              classes={{ root: classes.dropdownItem }}
              onClick={(e: any) => props.setToggle(false)}
              component={NavLink}
              activeClassName='active'
              to='/myDownloads'
            >
              <TheatersIcon classes={{ root: classes.dropdownIcon }} />
              My Downloads
            </Link>
          )}
          <Link
            className='dropdown-item'
            classes={{ root: classes.dropdownItem }}
            onClick={(e: any) => props.setToggle(false)}
            component={NavLink}
            activeClassName='active'
            to='/profile'
          >
            <PersonIcon classes={{ root: classes.dropdownIcon }} />
            Profile
          </Link>
        </>
      )}

      {props.user.role === 'admin' && (
        <Link
          className='dropdown-item'
          classes={{ root: classes.dropdownItem }}
          onClick={(e: any) => props.setToggle(false)}
          component={NavLink}
          activeClassName='active'
          to='/management'
        >
          <GroupsIcon classes={{ root: classes.dropdownIcon }} />
          Management
        </Link>
      )}

      <Link
        href='/auth/login'
        className='dropdown-item'
        classes={{ root: classes.dropdownItem }}
        onClick={async () => {
          await logout();
        }}
      >
        <MLogout />
        Sign out
      </Link>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.user,
  homePage: state.homePage,
  blockchain: state.blockchain,
});

const mapActionsToProps = {
  updateUser: UserActions.updateUser,
};

export default connect(mapStateToProps, mapActionsToProps)(DropdownOptions);
