import { Button, Grid, IconButton, ListItemIcon } from '@mui/material';
import clsx from 'clsx';
import md5 from 'md5';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TerritoryWindow from 'components/licenses/forms/windows/TerritoryWindow';
import {
  CommercialRightModel,
  RightWindowModel,
} from 'models/LicenseWindowModel';
import {
  CustomLicenseActions,
  LicenseTemplateActions,
  LicenseWindowActions,
} from 'redux/actions';
import { RootState } from 'redux/store';

import AddIcon from '@mui/icons-material/Add';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import licenseStyle from 'components/licenses/styles/LicenseStyles';

interface OwnProps {
  classes?: any;
  rightName: string;
  subRightName: string;
  clauseName: string;
  licenseType: string;
  operationMode: string;
}

interface DispatchProps {
  addTerritoryEntryLW: (payload: any) => void;
  removeClauseEntryLW: (payload: any) => void;
  addTerritoryEntryLT: (payload: any) => void;
  removeClauseEntryLT: (payload: any) => void;
  addTerritoryEntryCL: (payload: any) => void;
  removeClauseEntryCL: (payload: any) => void;
}

interface StateProps {
  clauseWindow: any;
  clausesTemplate: any;
}

type Props = StateProps & DispatchProps & OwnProps;

const ClausesWindowBody: React.FC<Props> = (props) => {
  const {
    clausesTemplate,
    operationMode,
    clauseName,
    clauseWindow,
    rightName,
    subRightName,
    licenseType,
    addTerritoryEntryLW,
    addTerritoryEntryLT,
    addTerritoryEntryCL,
    removeClauseEntryLW,
    removeClauseEntryLT,
    removeClauseEntryCL,
  } = props;
  const classes = licenseStyle();

  const handleAddTerritory = () => {
    const id = md5('' + new Date().getTime());

    // Add territory entry to the menu window
    addTerritoryEntryLW({
      rightName: rightName,
      subRightName: subRightName,
      clauseName: clauseName,
      territoryId: id,
    });

    // Add territory entry to license template
    if (operationMode === 'create') {
      if (licenseType === 'template')
        addTerritoryEntryLT({
          rightName: rightName,
          subRightName: subRightName,
          clauseName: clauseName,
          territoryId: id,
        });
      if (licenseType === 'custom')
        addTerritoryEntryCL({
          rightName: rightName,
          subRightName: subRightName,
          clauseName: clauseName,
          territoryId: id,
        });
    }
  };

  const AddTerritoryComponent = () => {
    return (
      <Button
        size='small'
        onClick={handleAddTerritory}
        className={classes.licenseButton}
      >
        <AddIcon /> Add Territory
      </Button>
    );
  };

  const RemoveClauseComponent = () => {
    const handleRemovePeriodOnClick = () => {
      const payload = {
        rightName: rightName,
        subRightName: subRightName,
        clauseName: clauseName,
      };

      // Add to clausesLeft to choose
      removeClauseEntryLW(payload);

      // Remove from license window state
      if (operationMode === 'create') {
        if (licenseType === 'template')
          removeClauseEntryLT({
            rightName: rightName,
            subRightName: subRightName,
            clauseName: clauseName,
            licenseType: licenseType,
            operationMode: operationMode,
          });

        if (licenseType === 'custom') removeClauseEntryCL(payload);
      }
    };

    return (
      <>
        <ListItemIcon>
          <IconButton size='small' onClick={handleRemovePeriodOnClick}>
            <HighlightOffIcon />
          </IconButton>
        </ListItemIcon>
      </>
    );
  };

  // const getAddTerritoryVisibility = () => {
  //   if (clauseWindow.territoriesLeftToBeChosen.length === 0) return 'hidden';
  //   else {
  //     // encontrar no minimo uma janela de territorio sem valor
  //     const noValueEntry = clauseWindow.territories.find(
  //       (element: any) => element.territory === ''
  //     );

  //     if (
  //       clauseWindow.territoriesLeftToBeChosen.length === 1 &&
  //       noValueEntry !== undefined
  //     )
  //       return 'hidden';
  //     else return 'visible';
  //   }
  // };

  useEffect(() => {
    if (operationMode === 'view') {
      for (let i = 0; i < clausesTemplate.territories.length; i++) {
        handleAddTerritory();
      }
    }
  }, []);

  let territories = clauseWindow.territories;

  if (operationMode === 'view') {
    territories = clausesTemplate.territories;
  }

  return (
    <>
      <div>
        <div className={clsx(classes.licenseSubtitle, classes.titleMargin)}>
          Period
        </div>

        <AddTerritoryComponent />
        <Grid
          className={classes.territoryContainer}
          container
          item
          sm={12}
          justifyContent='flex-start'
          sx={{marginTop: '20px'}}
        >
          {territories
            ? territories.map((item: any, index: any) => {
                return (
                  <TerritoryWindow
                    key={index}
                    classes={classes}
                    rightName={rightName}
                    subRightName={subRightName}
                    clauseName={clauseName}
                    licenseType={licenseType}
                    territoryId={item.territoryId}
                    operationMode={operationMode}
                  />
                );
              })
            : ''}
        </Grid>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const rightWindow = state.licenseWindow.openTabs.find(
    (element: RightWindowModel) => element.right === ownProps.rightName
  ) as CommercialRightModel;
  const clauseWindow =
    rightWindow !== undefined
      ? rightWindow[ownProps.subRightName].openTabs.find(
          (element: any) => element.clause === ownProps.clauseName
        )
      : null;

  return {
    clauseWindow: clauseWindow,
    clausesTemplate:
      state.licenseTemplate.commercialRights[ownProps.rightName].subRights[
        ownProps.subRightName
      ].clauses[ownProps.clauseName],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      addTerritoryEntryLW: LicenseWindowActions.addTerritoryEntryLW,
      removeClauseEntryLW: LicenseWindowActions.removeClauseEntryLW,

      /* LicenseTemplate */
      addTerritoryEntryLT: LicenseTemplateActions.addTerritoryEntryLT,
      removeClauseEntryLT: LicenseTemplateActions.removeClauseEntryLT,

      /* CustomLicense */
      addTerritoryEntryCL: CustomLicenseActions.addTerritoryEntryCL,
      removeClauseEntryCL: CustomLicenseActions.removeClauseEntryCL,
    },
    dispatch
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ClausesWindowBody);
