import { Grid } from '@mui/material';
import TextInput from 'components/controls/textInput/TextInput';
import { TemplateEducationModel } from 'models';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LicenseTemplateActions } from 'redux/actions';
import { RootState } from 'redux/store';

interface OwnProps {
  classes?: any;
  rightName: string;
  licenseType: string;
  operationMode: string;
  license: TemplateEducationModel;
}

interface DispatchProps {
  updateEduFieldLT: (payload: any) => void;
}

interface StateProps {
  eduTemplate: TemplateEducationModel;
}

type Props = StateProps & DispatchProps & OwnProps;

const EducationWindow: React.FC<Props> = ({
  classes,
  rightName,
  licenseType,
  license,
  eduTemplate,
  updateEduFieldLT,
}) => {
  const [price, setPrice] = useState('');
  const [duration, setDuration] = useState('');

  useEffect(() => {
    if (licenseType === 'custom') {
      setPrice(`${license.price} €`);
      setDuration(
        `${license.duration} ${license.duration === 1 ? 'Year' : 'Years'}`
      );
    } else {
      setPrice(eduTemplate.price !== -1 ? eduTemplate.price.toString() : '');
      setDuration(
        eduTemplate.duration !== 0 ? eduTemplate.duration.toString() : ''
      );
    }
  }, [licenseType, license, eduTemplate]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      const numericValue =
        value === '' ? '' : Math.max(0, parseInt(value, 10)).toString();
      field === 'price' || field === 'duration' ? setPrice(numericValue) : setDuration(numericValue);
      updateEduFieldLT({
        rightName,
        field,
        value: numericValue !== '' ? parseInt(numericValue, 10) : 0,
      });
    }
  };

  return (
    <>
      {[
        {
          label: 'Price',
          name: 'price',
          placeholder: 'Price in €',
          value: price,
          setter: setPrice,
        },
        {
          label: 'Duration',
          name: 'duration',
          placeholder: 'Years',
          value: duration,
          setter: setDuration,
        },
      ].map(({ label, name, placeholder, value }) => (
        <Grid container key={name} className={classes.educationalContainer}>
          <Grid item sm={6} className={classes.eduElement}>
            <div className={classes.eduRightFormControl}>
              <TextInput
                label={label}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={(e: any) => handleChange(e, name)}
                pageType='product'
                disabled={licenseType === 'custom'}
                required
              />
            </div>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  eduTemplate: state.licenseTemplate.nonCommercialRights[ownProps.rightName],
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    { updateEduFieldLT: LicenseTemplateActions.updateEduFieldLT },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EducationWindow);
