import { IconButton, ListItemIcon } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { commercialRightsDB, nonCommercialRightsDB } from 'database/rightsDB';
import {
  CustomLicenseModel,
  LicenseTemplateModel,
  LicenseWindowModel,
  UserModel,
} from 'models';
import {
  CustomLicenseActions,
  LicenseTemplateActions,
  LicenseWindowActions,
} from 'redux/actions';
import { RootState } from 'redux/store';

import { getBespokeLicense } from 'services/educationalLicenseService';
import { getProduct } from 'services/productService';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// import licenseStyle from 'components/licenses/styles/LicenseStyles';
import { isBuyer } from 'services/authService';
import RightsBody from './RightsBody';
import RightsHeader from './RightsHeader';

const DEFAULT_VALUE = '+';
const EDUCATIONAL_RIGHT = 'Educational';

interface OwnProps {
  classes?: any;
  productId: string;
  licenseId: string;
  loading?: boolean;
  licenseType: string; // template, custom (before was create and customize)
  operationMode: string; // view, edit, create
  setDisabledState?: (disabled: boolean) => void;
  setLoadingState?: (loading: boolean) => void;
}

interface DispatchProps {
  // License Windows
  updateRightsCurrentOpenTabLW: (payload: any) => void;
  addRightsTabLW: (payload: any) => void;
  removeFromRightsLeftToBeChosenLW: (payload: any) => void;
  removeRightEntryLW: (payload: any) => void;
  updateRightsLeftToBeChosenLW: (payload: any) => void;

  // License Templates
  addRightEntryLT: (payload: any) => void;
  updateClauseLT: (payload: any) => void;
  removeRightEntryLT: (payload: any) => void;
  updateLT: (payload: any) => void;
  resetLT: () => void;

  //Custom Licenses
  saveRightEntryCL: (payload: any) => void;
  removeRightEntryCL: (payload: any) => void;
  saveClauseEntryCL: (payload: any) => void;
  removeClauseEntryCL: (payload: any) => void;
}

interface StateProps {
  customLicense: CustomLicenseModel;
  licenseWindow: LicenseWindowModel;
  user: UserModel;
  licenseTemplate: LicenseTemplateModel;
}

type Props = StateProps & DispatchProps & OwnProps;

const LicenseWindow: React.FC<Props> = (props) => {
  const [rights, setRights] = useState([] as any);
  const [selectedSubright, setSelectedSubright] = useState('');
  const [licenseTemplate, setLicenseTemplate] = useState(props.licenseTemplate);

  const handleGetBespokeLicense = async (licenseTemplate: any) => {
    const temp = [];

    if (licenseTemplate) {
      temp.push(EDUCATIONAL_RIGHT);
    }

    // console.log('licenseTemplate', licenseTemplate);
    const newEduTemplate = {
      ...licenseTemplate.nonCommercialRights.Educational,
      duration: licenseTemplate.clauses[0].duration,
      price: licenseTemplate.clauses[0].price,
    };
    const newlicenseTemplate = {
      ...licenseTemplate,
      vizziID: licenseTemplate.TitleID,
      filmTitle: licenseTemplate.Title,
      nonCommercialRights: {
        Educational: newEduTemplate,
      },
    };
    setRights(temp);
    setLicenseTemplate(newlicenseTemplate);
    props.updateRightsLeftToBeChosenLW(temp);
  };

  // const setInputState = (name: string, value: any) => {
  //     setLicenseTemplate(prev  => ({
  //         ...prev,
  //         [name]: value
  //     }));
  // };

  useEffect(() => {
    const fetchData = async () => {
      /* Runs after the render */
      if (props.operationMode === 'create') {
        if (props.licenseType === 'custom') {
          await getBespokeLicense(props.productId).then(
            (value) => {
              // On run
              handleGetBespokeLicense(value);
            },
            (reason) => {
              // on fail
              console.log(reason);
            }
          );
        } else {
          await getProduct(props.productId, isBuyer()).then((value) => {
            // On run
            let temp: string[] = Object.keys(nonCommercialRightsDB);
            temp = temp.concat(Object.keys(commercialRightsDB));

            const newlicenseTemplate = {
              ...licenseTemplate,
              vizziID: value.id,
              filmTitle: value.title,
            };

            setRights(temp);
            setLicenseTemplate(newlicenseTemplate);

            props.updateRightsLeftToBeChosenLW(temp);
          });
        }
      } else if (props.operationMode === 'view') {
        await getBespokeLicense(props.licenseId).then(
          (value) => {
            // On run

            handleGetBespokeLicense(value);
          },
          (reason) => {
            // on fail
            console.log(reason);
          }
        );
      }

      if (props.setLoadingState) {
        props.setLoadingState(false);
      }
    };
    fetchData();
  }, []); //[props]

  useEffect(() => {
    for (let i = 0; i < props.licenseWindow.openTabs.length; i++) {
      const rightName = props.licenseWindow.openTabs[i].right;
      props.removeRightEntryLW(rightName);
    }

    for (let i = 0; i < rights.length; i++) {
      const rightName = rights[i];

      if (props.licenseType === 'template') {
        props.resetLT();

        props.removeRightEntryLT({
          rightName: rightName,
          licenseType: props.licenseType,
          operationMode: props.operationMode,
        });
      } else if (props.licenseType === 'custom') {
        resetCustomTree(rightName);
      }
    }
  }, []); //[rights, props]

  // Updates currently selected right
  const selectRight = (tab: string) => {
    const {
      licenseType,
      licenseWindow,
      licenseTemplate,
      addRightsTabLW,
      updateRightsCurrentOpenTabLW,
      removeFromRightsLeftToBeChosenLW,
    } = props;

    if (licenseWindow.currentOpenTab !== tab) {
      // Switch selected right -> Reset window to initial state
      if (licenseWindow.currentOpenTab !== DEFAULT_VALUE) {
        resetRight(licenseWindow.currentOpenTab);
      }

      setSelectedSubright('');

      updateRightsCurrentOpenTabLW(tab);
      removeFromRightsLeftToBeChosenLW(tab);

      if (licenseType === 'template') {
        addRightsTabLW({
          newTab: tab,
          template: null,
        });
      }

      if (licenseType === 'custom')
        addRightsTabLW({
          newTab: tab,
          template: licenseTemplate,
        });
    }
  };

  //   const selectSubright = (item: string) => {
  //     const { licenseType, operationMode, licenseWindow } = props;

  //     if (selectedSubright !== item) {
  //       setSelectedSubright(item);
  //       // License Templates: Since changes are only saved when the user presses "Apply"...
  //       // ...The data does not need to be reset
  //       if (
  //         licenseType === 'template' ||
  //         licenseType !== 'custom' ||
  //         operationMode !== 'create'
  //       ) {
  //         return;
  //       }

  //       resetSelectedClause(licenseWindow.currentOpenTab);
  //     }
  //   };

  const resetCustomTree = (rightName: string) => {
    const { customLicense, removeClauseEntryCL, removeRightEntryCL } = props;

    const isEducational = customLicense.nonCommercialRights[rightName];

    if (isEducational) {
      return removeRightEntryCL({ rightName: rightName });
    }

    const rightCustom = customLicense.commercialRights[rightName];

    for (const subRight in rightCustom) {
      for (const clause in rightCustom[subRight]) {
        removeClauseEntryCL({
          rightName: rightName,
          subRightName: subRight,
          clauseName: clause,
        });
      }
    }
  };

  const resetRight = (rightName: string) => {
    const {
      licenseType,
      operationMode,
      licenseWindow,
      customLicense,
      removeRightEntryCL,
      removeRightEntryLW,
    } = props;

    removeRightEntryLW(licenseWindow.currentOpenTab);

    // License Templates: Since changes are only saved when the user presses "Apply"...
    // ...The data does not need to be reset
    if (
      licenseType === 'template' ||
      licenseType !== 'custom' ||
      operationMode !== 'create'
    ) {
      return;
    }

    // If non-commercial right was previously saved, do not reset data
    if (customLicense.nonCommercialRights[rightName]?.saved) {
      return;
    }

    // Check if right is commercial or not...
    if (!customLicense.nonCommercialRights[rightName]) {
      resetSelectedClause(rightName);
    } else {
      removeRightEntryCL({
        rightName: rightName,
      });
    }
  };

  const resetSelectedClause = (rightName: string) => {
    const { customLicense, licenseWindow, removeClauseEntryCL } = props;

    const currentTree = getCurrentTree(licenseWindow.currentOpenTab);
    const isFullTreeSeleted = currentTree.subRight && currentTree.clauseInfo;
    if (!isFullTreeSeleted) {
      return;
    }

    const subRightName = currentTree.subRight as string;
    const clauseName = currentTree.clauseInfo.clause as string;

    const clause =
      customLicense.commercialRights[rightName][subRightName][clauseName];

    // If commercial right was previously saved, do not reset data
    if (clause.saved) {
      return;
    }

    removeClauseEntryCL({
      rightName: rightName,
      subRightName: subRightName,
      clauseName: clauseName,
    });
  };

  /**
   * Function to return the style of right option
   * If right was previously saved and is non-commercial -> savedButton
   * If right was selected by user -> activeButton
   */
  const selectedRightStyle = (item: string) => {
    const {
      classes,
      licenseType,
      licenseWindow,
      licenseTemplate,
      customLicense,
    } = props;

    if (
      licenseType === 'template' &&
      licenseTemplate.nonCommercialRights[item]?.allowed
    ) {
      return classes.savedButton;
    }

    if (
      licenseType === 'custom' &&
      customLicense.nonCommercialRights[item]?.saved
    ) {
      return classes.savedButton;
    }

    return licenseWindow.currentOpenTab === item ? classes.activeButton : '';
  };

  // Function to check if tree path was previously saved
  const checkIfSaved = (item: string) => {
    const { licenseType, licenseTemplate, customLicense } = props;

    if (
      licenseType === 'template' &&
      licenseTemplate.nonCommercialRights[item]?.allowed
    ) {
      return true;
    } else if (
      licenseType === 'custom' &&
      customLicense.nonCommercialRights[item]?.saved
    ) {
      return true;
    } else return false;
  };

  // Function to return the style of sub-right option
  const selectedSubrightStyle = (item: string) => {
    const { classes } = props;
    return selectedSubright === item ? classes.activeButton : '';
  };

  const RemoveRightComponent = (props: any) => {
    const handleRemovePeriodOnClick = () => {
      // Add to clausesLeft to choose
      props.removeRightEntryLW(props.rightName);

      // Remove from license window state
      if (props.operationMode === 'create') {
        if (props.licenseType === 'template') {
          props.removeRightEntryLT({
            rightName: props.rightName,
            licenseType: props.licenseType,
            operationMode: props.operationMode,
          });
        } else if (props.licenseType === 'custom') {
          props.removeRightEntryCL({
            rightName: props.rightName,
          });
        }
      }
    };

    return (
      <>
        <ListItemIcon>
          <IconButton size='small' onClick={handleRemovePeriodOnClick}>
            <HighlightOffIcon />
          </IconButton>
        </ListItemIcon>
      </>
    );
  };

  /**
   * Get enabled sub-rights depending on license type
   */
  const getEnabledSubRights = (rightName: string) => {
    console.log("PASSA NA FUNCAO", rightName, props.licenseType)
    if (props.licenseType === 'template')
      if (commercialRightsDB[rightName]) {
        return Object.keys(commercialRightsDB[rightName]);
      }
      if (nonCommercialRightsDB[rightName]) {
        return Object.keys(nonCommercialRightsDB[rightName]);
      }
      console.error(`Error ${rightName} not found`);
      // return Object.keys(commercialRightsDB[rightName]);
    if (props.licenseType === 'custom') {
      const subRights =
        props.licenseTemplate.commercialRights[rightName].subRights;
      const keys = Object.keys(subRights);
      const allowedClauses = [];

      for (let i = 0; i < keys.length; i++) {
        if (subRights[keys[i]].allowed) allowedClauses.push(keys[i]);
      }
      return allowedClauses;
    } else return [];
  };

  /**
   * Retrieves selected sub-right name and selected clause information
   * Clause information includes: list of territories and clause name
   */
  const getCurrentTree = (rightName: string) => {
    const emptyResult = {
      clauseInfo: undefined,
      subRight: undefined,
    };

    if (rightName === EDUCATIONAL_RIGHT) {
      return emptyResult;
    }

    const currentRightValue = props.licenseWindow.currentOpenTab;
    const openTabs = props.licenseWindow.openTabs;

    if (currentRightValue === DEFAULT_VALUE || openTabs.length === 0) {
      return emptyResult;
    }

    const rightWindow = openTabs[0];

    const rightKeys = Object.keys(rightWindow);
    const rightValues = Object.values(rightWindow);

    let clauseInfo;
    let subRight;
    for (let i = 0; i < rightValues.length; i++) {
      if (rightValues[i].openTabs && rightValues[i].openTabs.length > 0) {
        subRight = rightKeys[i];
        clauseInfo = rightValues[i].openTabs[0];
      }
    }

    return {
      clauseInfo: clauseInfo,
      subRight: subRight,
    };
  };

  /**
   * Check if "Apply" button should be disabled or not
   * Commercial rights: always enabled
   * Non-commercial rights: disabled if no valid territories are selected
   */
  const isButtonDisabled = (rightName: string) => {
    if (rightName === EDUCATIONAL_RIGHT) {
      return false;
    } else {
      // return true;

      const { licenseWindow } = props;
      const currentTree = getCurrentTree(licenseWindow.currentOpenTab);
      const clause = currentTree.clauseInfo;

      if (!clause) {
        return true;
      }

      const filteredTerritories = clause.territories.find(
        (element: any) => element.territory !== ''
      );

      if (filteredTerritories) {
        return false;
      }
    }
  };

  /**
   * Handle save button click
   * Saves current tree/territories selection in Redux state
   * Saves both custom licenses and license templates
   */
  const handleSaveClick = () => {
    const {
      licenseWindow,
      licenseType,
      operationMode,
      addRightEntryLT,
      updateClauseLT,
      saveClauseEntryCL,
      saveRightEntryCL,
      removeRightEntryLW,
      setDisabledState,
    } = props;

    const currentTree = getCurrentTree(licenseWindow.currentOpenTab);
    const isCommercial = currentTree.subRight && currentTree.clauseInfo;

    if (licenseType === 'template' && operationMode === 'create') {
      if (isCommercial) {
        const clauseName = currentTree.clauseInfo.clause;

        updateClauseLT({
          rightName: licenseWindow.currentOpenTab,
          subRightName: currentTree.subRight,
          clauseName: clauseName,
          licenseType: licenseType,
          operationMode: operationMode,
        });
      }

      addRightEntryLT({ rightName: licenseWindow.currentOpenTab });
    } else if (licenseType === 'custom' && operationMode === 'create') {
      if (isCommercial) {
        const clauseName = currentTree.clauseInfo.clause;

        saveClauseEntryCL({
          rightName: licenseWindow.currentOpenTab,
          subRightName: currentTree.subRight,
          clauseName: clauseName,
        });
      }

      saveRightEntryCL({ rightName: licenseWindow.currentOpenTab });
    }

    if (setDisabledState) {
      setDisabledState(false);
    }

    // Reset to initial state
    removeRightEntryLW(licenseWindow.currentOpenTab);
  };

  const handleSetSelectedSubright = useCallback((subright: string) => {
    console.log('Updating selectedSubright:', subright);
    setSelectedSubright(subright);
  }, []);

  return (
    <div>
      <RightsHeader
        classes={props.classes}
        rights={rights}
        loading={props.loading}
        licenseTemplate={licenseTemplate}
        selectedRightStyle={selectedRightStyle}
        selectRight={selectRight}
        checkIfSaved={checkIfSaved}
      />
      <RightsBody
        classes={props.classes}
        licenseWindow={props.licenseWindow}
        operationMode={props.operationMode}
        licenseType={props.licenseType}
        licenseTemplate={licenseTemplate}
        rights={rights}
        isButtonDisabled={isButtonDisabled}
        handleSaveClick={handleSaveClick}
        getEnabledSubRights={getEnabledSubRights}
        selectedSubrightStyle={selectedSubrightStyle}
        selectedSubright={selectedSubright}
        handleSetSelectedSubright={handleSetSelectedSubright}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  customLicense: state.customLicense,
  licenseWindow: state.licenseWindow,
  licenseTemplate: state.licenseTemplate,
  user: state.user,
});

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      /* License Window */
      updateRightsCurrentOpenTabLW:
        LicenseWindowActions.updateRightsCurrentOpenTabLW,
      addRightsTabLW: LicenseWindowActions.addRightsTabLW,
      removeFromRightsLeftToBeChosenLW:
        LicenseWindowActions.removeFromRightsLeftToBeChosenLW,
      removeRightEntryLW: LicenseWindowActions.removeRightEntryLW,
      updateRightsLeftToBeChosenLW:
        LicenseWindowActions.updateRightsLeftToBeChosenLW,

      /* License Template */
      addRightEntryLT: LicenseTemplateActions.addRightEntryLT,
      updateClauseLT: LicenseTemplateActions.updateClauseLT,
      removeRightEntryLT: LicenseTemplateActions.removeRightEntryLT,
      updateLT: LicenseTemplateActions.updateLT,
      resetLT: LicenseTemplateActions.resetLT,

      /* Custom License */
      saveRightEntryCL: CustomLicenseActions.saveRightEntryCL,
      removeRightEntryCL: CustomLicenseActions.removeRightEntryCL,
      saveClauseEntryCL: CustomLicenseActions.saveClauseEntryCL,
      removeClauseEntryCL: CustomLicenseActions.removeClauseEntryCL,
    },
    dispatch
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(LicenseWindow);
