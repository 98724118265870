import { Autocomplete, FormLabel, TextField } from '@mui/material';
import React from 'react';
import autocompleteStyle from './AutocompleteStyles';

interface OwnProps {
  id: string;
  classes?: any;
  label: string;
  name: string;
  value: any;
  pageType: any;
  options: any[];
  placeholder: string;
  disabled?: boolean;
  auth: boolean;
  required: boolean;
  getOptionLabel: (option: any) => string;
  onChange: (event: any, newValue: string | null, reason: string) => void;
  getOptionSelected: (option: any, value: any) => boolean;
}

const AutocompleteInput: React.FC<OwnProps> = ({
  id,
  label,
  options,
  value,
  disabled = false,
  placeholder,
  auth,
  required,
  getOptionLabel,
  getOptionSelected,
  onChange,
  pageType,
}) => {
  const classes = autocompleteStyle();

  return (
    <>
      <FormLabel
        className={pageType === 'product' ? classes.labelBlack : classes.label}
        component='legend'
      >
        {label}
      </FormLabel>
      <Autocomplete
        classes={{
          root: classes.autocompleteRoot,
          inputRoot: auth
            ? classes.autocompleteInputAuth
            : classes.autocompleteInput,
        }}
        id={id}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={getOptionSelected}
        onChange={onChange}
        value={value}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant='outlined'
            required={required}
          />
        )}
      />
    </>
  );
};

export default AutocompleteInput;