import { Grid } from '@mui/material';
import UploadIcon from 'assets/icons/uploadIcon.svg';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactPlayer from 'react-player';

import { project } from 'project';
import dropzoneStyle from './DropzoneStyles';

interface DropzoneProps {
  propVideo: File | null;
  setPropVideo: (acceptedFiles: File) => void;
  classes?: any;
}

const VideoDropzone: React.FC<DropzoneProps> = ({
  propVideo,
  setPropVideo,
}) => {
  const classes = dropzoneStyle();
  const [video, setVideo] = useState<string | null>(null);

  useEffect(() => {
    if (propVideo) {
      if (typeof propVideo === 'string') {
        setVideo(propVideo);
      } else {
        setVideo(URL.createObjectURL(propVideo));
      }
    }
  }, [propVideo]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'video/mp4': ['.mp4'],
      'video/quicktime': ['.mov'],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const selectedVideo = acceptedFiles[0];
        setVideo(URL.createObjectURL(selectedVideo));
        setPropVideo(selectedVideo);
      }
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {video ? (
          <ReactPlayer
            className={classes.reactPlayerContainer}
            url={video}
            controls
            width='100%'
            height='auto'
          />
        ) : (
          <Grid item xs={12}>
            <div
              {...getRootProps()}
              className={classes.labelsContainer}
              style={
                project === 'scene' ? { height: '200px' } : { height: '100%' }
              }
            >
              <input {...getInputProps()} />
              <div className={classes.pContainer}>
                <img src={UploadIcon} />
                {/* <p className={classes.pStyle}>Upload video</p> */}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default VideoDropzone;
