import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import LicenseWindow from 'components/licenses/forms/windows/LicenseWindow';
import { CustomLicenseModel, LicenseTemplateModel } from 'models';
import ContractPreview from 'pages/products/ContractPreview';
import { RootState } from 'redux/store';
// import { PrepareLicensePurchase } from 'services/educationalLicenseService';
import { getUser } from 'services/userService';

import dashboardStyle from 'styles/dashboard';

interface OwnProps {
  classes?: any;
  history: any;
  match: any;
}

interface StateProps {
  customLicense: CustomLicenseModel;
  licenseTemplate: LicenseTemplateModel;
}

// interface State {
//   loading: boolean;
//   disabledBtn: boolean;
//   licenseError: string;
//   hasOrg: boolean;
//   dialog: DialogModel;
// }

type Props = StateProps & OwnProps;

const CustomizeLicensePage: React.FC<Props> = (props) => {
  const openContractPreview = (dialogValue: boolean) => {
    setDialog((prevDialog) => ({
      ...prevDialog,
      open: dialogValue,
    }));
  };

  const [loading, setLoading] = useState(true);
  const [disabledBtn, setDisabledBtn] = useState(true);
  // const [licenseError, setLicenseError] = useState('');
  const [hasOrg, setHasOrg] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    setOpenPopup: openContractPreview,
    title: 'Contract Preview',
  });

  const dashboardClasses = dashboardStyle();
  const productId = props.match.params.id;

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUser();
      if (user.org) {
        setHasOrg(true);
      }
      setLoading(false);
    };

    fetchUser();
  }, []);

  // const addToCart = async () => {
  //   const productId = props.match.params.id;
  //   setLoading(true);

  //   await PrepareLicensePurchase(productId).then(
  //     (value) => {
  //       // On run
  //       setLoading(false);
  //       props.history.push('/myCustomLicenses');
  //       // window.location.reload();
  //     },
  //     (reason) => {
  //       // on fail
  //       setLoading(false);
  //       setLicenseError(reason);
  //       console.log(reason);
  //     }
  //   );
  // };

  const setDisabledState = (disabled: boolean) => {
    // console.log('disabled', disabled);
    setDisabledBtn(disabled);
  };

  const setLoadingState = (loading: boolean) => {
    setLoading(loading);
  };

  return (
    <div className={dashboardClasses.content}>
      <div className={dashboardClasses.headerWithoutMargin}>
        <Typography
          variant='h5'
          className={dashboardClasses.dashboardSubtitleBlue}
        >
          Customize License
        </Typography>
      </div>

      <Grid container>
        <Grid item xs={12} md={12}>
          {hasOrg ? (
            <LicenseWindow
              licenseType='custom'
              productId={productId}
              licenseId=''
              operationMode='create'
              setDisabledState={setDisabledState.bind(this)}
              loading={loading}
              setLoadingState={setLoadingState}
            />
          ) : (
            <></>
          )}
          <div className={dashboardClasses.footerContainer}>
            <NavLink
              className={dashboardClasses.dashLinkBtn}
              to={`/movies/${productId}`}
            >
              <Button
                className={dashboardClasses.secondaryBtn}
                variant='outlined'
              >
                Back to Film
              </Button>
            </NavLink>

            <Button
              className={dashboardClasses.dashboardButton}
              variant='outlined'
              disableRipple
              disabled={hasOrg ? disabledBtn : true}
              onClick={() => {
                setDialog((prevDialog) => ({
                  ...prevDialog,
                  open: true,
                }));
              }}
            >
              Purchase License
            </Button>
          </div>
        </Grid>
      </Grid>

      <ContractPreview
        openPopup={dialog.open}
        setOpenPopup={dialog.setOpenPopup}
        dialogTitle={dialog.title}
        collectionId={productId}
        history={props.history}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  customLicense: state.customLicense,
  licenseTemplate: state.licenseTemplate,
});

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
  mapStateToProps
)(CustomizeLicensePage);
