import { FormLabel, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR } from 'styles/colors';

const useStyles = makeStyles<Theme>(() => ({
  label: (props: { color?: string }) => ({
    fontSize: 16,
    fontWeight: 'bold !important',
    color: `${props.color} !important`,
    textTransform: 'uppercase',
    lineHeight: '19px',
    paddingBottom: '8px',
    '&.Mui-focused': {
      color: `${props.color || PRIMARY_COLOR} !important`,
    },
    '&.Mui-error': {
      color: props.color || PRIMARY_COLOR,
    },
  }),
  description: {
    fontSize: 16,
    color: 'black',
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
}));

interface MFieldDataProps {
  label: string;
  description?: string;
  color?: string;
}

const FieldData: React.FC<MFieldDataProps> = ({
  label,
  description,
  color,
}) => {
  const classes = useStyles({ color });

  return (
    <div className='form-data'>
      <FormLabel
        classes={{ root: classes.label }}
        component='legend'
      >
        {label}
      </FormLabel>
      {description && (
        <Typography classes={{ root: classes.description }} component='div'>
          {description}
        </Typography>
      )}
    </div>
  );
};

export default FieldData;
