import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { SidebarItem, UserModel } from 'models';
import { RootState } from 'redux/store';
import { isBuyer, isSeller } from 'services/authService';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import GroupsIcon from '@mui/icons-material/Groups';
import MovieIcon from '@mui/icons-material/Movie';
import PersonIcon from '@mui/icons-material/Person';
import TheatersIcon from '@mui/icons-material/Theaters';
import sidebarStyle from './SidebarStyles';

import { project } from 'project';
import { FOURTH_COLOR } from 'styles/colors';

interface OwnProps {
  classes?: any;
  open: boolean;
  handleDrawerClose: (payload: any) => void;
}

interface StateProps {
  user: UserModel;
}

type Props = StateProps & OwnProps;

const isMovieActive = (match: any, location: any) => {
  const pathname: string = location.pathname;
  if (pathname === '/movies' || pathname === '/catalogue') {
    return true;
  } else if (
    pathname.startsWith('/movies') ||
    pathname.startsWith('/catalogue')
  ) {
    return true;
  } else return false;
};

const LeftSideBar: React.FC<Props> = ({ user }) => {
  const classes = sidebarStyle();
  const [collapsed, setCollapsed] = useState(false);
  const [libraryOpen, setLibraryOpen] = useState(true);
  const [activeSubitem, setActiveSubitem] = useState<
    'collections' | 'films' | null
  >('collections');

  const mainListItems: SidebarItem[] = [];

  if (user.role !== 'admin') {
    mainListItems.push({
      name: isSeller()
        ? project === 'scene'
          ? 'Library'
          : 'My Films'
        : project === 'scene'
          ? 'Library'
          : 'Films',
      url:
        project === 'scene'
          ? activeSubitem === 'collections'
            ? '/collections'
            : '/catalogue'
          : '/catalogue',
      icon: <MovieIcon />,
      isActive: isMovieActive,
    });

    if (isSeller() && project !== 'mfh') {
      mainListItems.push({
        name: 'My Bespoke Licenses',
        url: '/myBespokeLicenses',
        icon: <AssignmentTurnedInIcon />,
      });
    }

    if (project !== 'mfh') {
      mainListItems.push({
        name: 'My Pending Licenses',
        url: '/myPendingLicenses',
        icon: <AssignmentTurnedInIcon />,
      });
    }

    if (isBuyer() && project === 'mfh') {
      mainListItems.push({
        name: 'My Downloads',
        url: '/myDownloads',
        icon: <TheatersIcon />,
      });
    }

    if (user.role !== 'user') {
      mainListItems.push({
        name: 'My Signed Licenses',
        url: '/myCustomLicenses',
        icon: <AssignmentIcon />,
      });
    }
    mainListItems.push({
      name: 'Profile',
      url: '/profile',
      icon: <PersonIcon />,
    });
  }

  if (user.role === 'admin') {
    mainListItems.push({
      name: 'Management',
      url: '/management',
      icon: <GroupsIcon />,
    });
  }

  return (
    <>
      {/* <Drawer
        variant='permanent'
        classes={{
          paper: `${classes.sidebar} ${collapsed ? classes.sidebarCollapsed : ''}`,
        }}
        open={true}
      > */}
      <Drawer
        variant='permanent'
        sx={{
          '& .MuiDrawer-paper': {
            width: collapsed ? 80 : 270,
            position: 'relative',
            backgroundColor: FOURTH_COLOR,
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            border: 'none',
            zIndex: 1000,
            transition: 'width 0.3s ease-in-out',
          },
        }}
        open={true}
      >
        {/* <List className={classes.itemsContainer} sx={{marginTop: 5}}> */}
        <List sx={{ marginTop: 5 }}>
          <div
            className={`${collapsed ? classes.toolbarLogo : classes.toolbarLogoCollapsed}`}
          >
            <IconButton
              onClick={() => {
                setCollapsed(!collapsed);
                setLibraryOpen(false);
              }}
            >
              {collapsed ? (
                <MenuIcon sx={{ color: 'white' }} />
              ) : (
                <ChevronLeftIcon sx={{ color: 'white' }} />
              )}
            </IconButton>
          </div>

          {/* Main List Items */}
          {mainListItems.map((item: SidebarItem, index: number) => (
            <div key={index}>
              {item.name === 'Library' ? (
                <>
                  <NavLink
                    key={index}
                    className={`${classes.sidebarItemLink} libraryText`}
                    activeClassName='active'
                    isActive={(match, location) =>
                      location.pathname.startsWith('/collections') ||
                      location.pathname.startsWith('/collection') ||
                      location.pathname.startsWith('/catalogue') ||
                      location.pathname.startsWith('/movies') ||
                      location.pathname.startsWith('/movie')
                    }
                    to={item.url}
                  >
                    <ListItem
                      button
                      onClick={() => setLibraryOpen(!libraryOpen)}
                      className={classes.sidebarItem}
                    >
                      <ListItemIcon className={classes.sidebarItemIcon}>
                        {item.icon}
                      </ListItemIcon>
                      {!collapsed && (
                        <ListItemText
                          classes={{ primary: classes.sidebarItemTxt }}
                          primary={item.name}
                        />
                      )}
                      {!collapsed &&
                        (libraryOpen ? <ExpandLess /> : <ExpandMore />)}
                    </ListItem>
                    {!collapsed && (
                      <Collapse in={libraryOpen} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                          <NavLink
                            to='/collections'
                            className={classes.sidebarItemLink}
                            activeClassName='active activeSub'
                            isActive={(match, location) =>
                              location.pathname.startsWith('/collections') ||
                              location.pathname.startsWith('/collection')
                            }
                            onClick={() => setActiveSubitem('collections')}
                          >
                            <ListItem button className={classes.sidebarItem}>
                              <ListItemIcon
                                className={classes.sidebarItemIcon}
                              />
                              <ListItemText
                                classes={{ primary: classes.sidebarItemTxt }}
                                primary='Collections'
                              />
                            </ListItem>
                          </NavLink>
                          <NavLink
                            to='/catalogue'
                            className={classes.sidebarItemLink}
                            activeClassName='active activeSub'
                            isActive={(match, location) =>
                              location.pathname.startsWith('/catalogue') ||
                              location.pathname.startsWith('/movies') ||
                              location.pathname.startsWith('/movie')
                            }
                            onClick={() => setActiveSubitem('films')}
                          >
                            <ListItem button className={classes.sidebarItem}>
                              <ListItemIcon
                                className={classes.sidebarItemIcon}
                              />
                              <ListItemText
                                classes={{ primary: classes.sidebarItemTxt }}
                                primary='Films'
                              />
                            </ListItem>
                          </NavLink>
                        </List>
                      </Collapse>
                    )}
                  </NavLink>
                </>
              ) : (
                <NavLink
                  key={index}
                  // className={(isActive: boolean) =>
                  //   `${classes.sidebarItemLink} ${classes.sidebarItemLink} ${isActive ? 'active' : ''}`
                  // }
                  className={classes.sidebarItemLink}
                  activeClassName='active'
                  isActive={item.isActive}
                  to={item.url}
                >
                  <ListItem className={classes.sidebarItem}>
                    <ListItemIcon className={classes.sidebarItemIcon}>
                      {item.icon}
                    </ListItemIcon>
                    {!collapsed && (
                      <ListItemText
                        classes={{ primary: classes.sidebarItemTxt }}
                        primary={item.name}
                      />
                    )}
                  </ListItem>
                </NavLink>
              )}
            </div>
          ))}
        </List>
        <div className={classes.sidebarFooter}>
          <span className={classes.sidebarFooterTxt}>
            {/* Project supported by EIT */}
          </span>
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

export default connect<StateProps, Record<string, never>, OwnProps, RootState>(
  mapStateToProps
)(LeftSideBar);
