import { Button, Typography } from '@mui/material';
import LicenseWindow from 'components/licenses/forms/windows/LicenseWindow';
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import dashboardStyle from 'styles/dashboard';

const LicenseTemplate: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const dashboardClasses = dashboardStyle();
  const { templateId } = useParams<{ templateId: string }>();

  useEffect(() => {
    /*getProduct(templateId)
            .then(value => this.setState({ movieTitle: value.title }))
            .catch(err => console.error(err))*/
  }, [templateId]);

  return (
    <>
      <div>
        <div className={dashboardClasses.headerWithoutMargin}>
          <Typography
            variant='h5'
            className={dashboardClasses.dashboardSubtitleBlue}
          >
            Bespoke License
          </Typography>

          <NavLink
            className={dashboardClasses.dashLinkBtn}
            to={'/movies/' + templateId}
          >
            <Button
              className={dashboardClasses.dashboardButton}
              variant='outlined'
            >
              Go to Film Page
            </Button>
          </NavLink>
        </div>

        <LicenseWindow
          licenseType='template'
          operationMode='view'
          productId=''
          licenseId={templateId}
          loading={loading}
          setLoadingState={setLoading}
        />
      </div>

      <div className={dashboardClasses.footerContainer}>
        <NavLink
          className={dashboardClasses.dashLinkBtn}
          to='/myBespokeLicenses'
        >
          <Button className={dashboardClasses.secondaryBtn} variant='outlined'>
            Back to My Bespoke Licenses
          </Button>
        </NavLink>
      </div>
    </>
  );
};

export default LicenseTemplate;
