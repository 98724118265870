import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { createProduct } from 'services/productService';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';

import AddMedia from 'pages/products/addFilms/AddMedia';
import FilmDetails from 'pages/products/addFilms/FilmDetails';

import lineSplit from 'assets/images/lineSplit.svg';

import Swal from 'sweetalert2';

import { ProductVersionForm, ProductWithPoster } from 'models/ProductsModel';

import dashboardStyle from 'styles/dashboard';

import { QueryClient, QueryClientProvider, useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
interface OwnProps {
  classes?: any;
  history: any;
  match: any;
}

interface State {
  product: ProductWithPoster;
  activeStep: number;
  skipped: Set<number>;
  imageURL: string;
  edited: boolean;
  addMedia: ProductVersionForm[];
  formErrorMessage: string;
}

const queryClient = new QueryClient();

const CreateProductPageWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CreateProductPage {...props} />
    </QueryClientProvider>
  );
};

const CreateProductPage: React.FC<OwnProps> = () => {
  const classes = dashboardStyle();
  const history = useHistory();

  const { collectionId } = useParams<{ collectionId?: string }>();

  const [addMedia, setAddMedia] = useState<ProductVersionForm[]>([]);
  const [addSubtitles, setAddSubtitles] = useState<
    { files: any; language: string }[]
  >([]);

  const [product, setProduct] = useState<ProductWithPoster>({
    title: '',
    genres: [],
    language: 'English',
    name: '',
    online: false,
    associatedCollections: collectionId ? [collectionId] : [],
  });

  const handleFilmStatusQuery = (
    status: { index: number; status: string; id: string }[]
  ) => {
    setAddMedia((prevMedia) =>
      prevMedia.map((media, index) => {
        const foundStatus = status.find((s) => s.index === index);
        if (foundStatus) {
          return {
            ...media,
            status: foundStatus.status,
            vizziID: foundStatus.id,
          };
        }
        return media;
      })
    );
  };

  const handleFilmVersionAdd = (index: number, uuid: string) => {
    setAddMedia((prevMedia) =>
      prevMedia.map((media, idx) => {
        if (idx === index) {
          return {
            ...media,
            uuid,
            status: 'UPLOADING',
          };
        }
        return media;
      })
    );
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const handleDropPoster = (acceptedFiles: File) => {
    // console.log('Files dropped:', acceptedFiles);
    setProduct((prevProduct) => ({
      ...prevProduct,
      poster: acceptedFiles,
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;
    if (value === '') {
      // If the value is empty, remove the key from the product state
      setProduct((prevProduct) => {
        const updatedProduct = { ...prevProduct };
        delete updatedProduct[name as keyof typeof updatedProduct];
        return updatedProduct;
      });
    } else if (name === 'year') {
      if (!isNaN(Number(value)) && !value.includes('-') && value.length <= 4) {
        // If the value is a valid year, update the 'year' property
        setProduct((prevProduct) => ({
          ...prevProduct,
          year: parseInt(value),
        }));
      }
    } else if (name === 'duration') {
      if (!isNaN(Number(value)) && !value.includes('-')) {
        // If the value is a valid number, update the 'duration' property
        setProduct((prevProduct) => ({
          ...prevProduct,
          duration: parseInt(value),
        }));
      }
    } else if (type === 'checkbox') {
      setProduct((prevProduct) => ({
        ...prevProduct,
        online: checked,
      }));
    } else {
      // For other fields, update the product state with the new value
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleLangChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any
  ) => {
    const { name, value } = event.target;
    if (newValue === '') {
      // If the value is empty, remove the key from the product state
      setProduct((prevProduct) => {
        const updatedProduct = { ...prevProduct };
        delete updatedProduct.language;
        return updatedProduct;
      });
      return;
    }
    if (newValue !== undefined) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        language: newValue.name,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleRatingChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any
  ) => {
    const { name, value } = event.target;
    if (newValue === '') {
      // If the value is empty, remove the key from the product state
      setProduct((prevProduct) => {
        const updatedProduct = { ...prevProduct };
        delete updatedProduct.contentRating;
        return updatedProduct;
      });
      return;
    }
    if (newValue !== undefined) {
      setProduct((prevProduct) => ({
        ...prevProduct,
        contentRating: newValue.code,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleGenresChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    reason: string
  ) => {
    const { name, value } = event.target;
    if (reason === 'select-option' || reason === 'remove-option') {
      const selectedGenres = newValue;
      setProduct((prevProduct) => ({
        ...prevProduct,
        genres: selectedGenres,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleRegionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: any,
    reason: string
  ) => {
    const { name, value } = event.target;
    if (reason === 'select-option' || reason === 'remove-option') {
      const selectedRegions = newValue;
      setProduct((prevProduct) => ({
        ...prevProduct,
        regions: selectedRegions,
      }));
    } else {
      setProduct((prevProduct) => ({
        ...prevProduct,
        [name]: value,
      }));
    }
  };

  const handleCollectionSelect = (newValue: any[]) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      associatedCollections: newValue,
    }));
  };

  const handleCreateNewVersion = () => {
    setAddMedia((prevMedia) => [...prevMedia, { version: '', video: null }]);
  };

  const handleAddSubtitleForm = () => {
    setAddSubtitles((prevSubtitles) => [
      ...prevSubtitles,
      { files: '', language: '' },
    ]);
  };

  const handleRemoveSubtitleForm = (index: number) => {
    setAddSubtitles((prevSubtitles) =>
      prevSubtitles.filter((_, i) => i !== index)
    );
  };

  const handleRemoveForm = (index: number) => {
    setAddMedia((prevMedia) => prevMedia.filter((_, i) => i !== index));
  };

  const handleInputChange = (
    index: number,
    field: keyof ProductVersionForm,
    value: string
  ) => {
    setAddMedia((prevMedia) =>
      prevMedia.map((media, idx) => {
        if (idx === index) {
          return {
            ...media,
            [field]: value,
          };
        }
        return media;
      })
    );
  };

  const handleSubtitleChange = (
    index: number,
    field: string,
    value: string
  ) => {
    setAddSubtitles((prevMedia) =>
      prevMedia.map((media, idx) => {
        if (idx === index) {
          return {
            ...media,
            [field]: value,
          };
        }
        return media;
      })
    );
  };

  const handleInputDropChange = (
    index: number,
    field: keyof ProductVersionForm,
    acceptedFiles: File[]
  ) => {
    setAddMedia((prevMedia) =>
      prevMedia.map((media, idx) => {
        if (idx === index) {
          return {
            ...media,
            [field]: acceptedFiles,
          };
        }
        return media;
      })
    );
  };

  const handleSubtitleDropChange = (index: number, acceptedFiles: File[]) => {
    setAddSubtitles((prevMedia) =>
      prevMedia.map((media, idx) => {
        if (idx === index) {
          return {
            ...media,
            files: acceptedFiles,
          };
        }
        return media;
      })
    );
  };

  const mutation = useMutation(
    async (data: {
      product: ProductWithPoster;
      mediaList: ProductVersionForm[];
    }) => {
      const { poster, ...productVersionForm } = data.product;
      //for each media in mediaList, get vizziID and add it to a list
      const vizziIDList = data.mediaList
        .filter((media) => media.vizziID !== undefined)
        .map((media) => media.vizziID) as string[];
      productVersionForm.versions = vizziIDList;

      const collections: string[] = Array.isArray(
        data.product.associatedCollections
      )
        ? data.product.associatedCollections.map((collection) =>
            typeof collection === 'string'
              ? collection
              : (collection as { id: string }).id
          )
        : [];

      const updatedProductData = {
        ...productVersionForm,
        associatedCollections: collections,
      };
      console.log('antes de enviar', updatedProductData);

      return createProduct(updatedProductData, poster);
    },
    {
      onSuccess: () => {
        setTimeout(() => {
          if (collectionId) {
            history.push(`/collections/${collectionId}`);
          } else {
            history.push('/catalogue');
          }
        }, 500);
      },
      onError: (error: any) => {
        Swal.fire({
          icon: 'error',
          iconColor: PRIMARY_COLOR,
          text: error.message,
          showConfirmButton: false,
        });
      },
    }
  );

  console.log('subtitles !!', addSubtitles);
  return (
    <React.Fragment>
      <div>
        <Grid container className={classes.customGrid}>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            xl={12}
            style={{ justifyContent: 'space-between' }}
          >
            <Typography
              className={classes.dashboardTitle}
              variant='h4'
              sx={{ fontWeight: 'bold' }}
            >
              Add film
            </Typography>

            <div className={classes.productBtnContainer}>
              <button
                className={`${classes.secondaryBtn} ${classes.btnSpaces}`}
                onClick={handleGoBack}
              >
                <span className={classes.optionDescription}>
                  Back to my films
                </span>
              </button>
              <button
                className={classes.dashboardButton}
                type='submit'
                onClick={() =>
                  mutation.mutate({ product: product, mediaList: addMedia })
                }
                disabled={
                  !addMedia.every((item) => {
                    return item.vizziID !== undefined;
                  }) || product.title === ''
                }
                // disabled={product.title === ''}
              >
                {mutation.isLoading ? (
                  <Box sx={{ display: 'inline', paddingTop: '8px' }}>
                    <CircularProgress
                      sx={{
                        color: THIRD_COLOR,
                        width: '25px !important',
                        height: '25px !important',
                      }}
                    />
                  </Box>
                ) : (
                  'Save'
                )}
              </button>
            </div>
          </Grid>
          <img src={lineSplit} className={classes.lineTop} />

          <FilmDetails
            product={product}
            handleDrop={handleDropPoster}
            handleChange={handleChange}
            handleLangChange={handleLangChange}
            handleRatingChange={handleRatingChange}
            handleGenresChange={handleGenresChange}
            handleRegionChange={handleRegionChange}
            handleCollectionSelect={handleCollectionSelect}
            edit={false}
          />
          <AddMedia
            product={product}
            classes={classes}
            productMedia={addMedia}
            handleAddForm={handleCreateNewVersion}
            handleRemoveForm={handleRemoveForm}
            handleInputChange={handleInputChange}
            handleDropChange={handleInputDropChange}
            productMediaSubtitles={addSubtitles}
            handleAddFormSubtitle={handleAddSubtitleForm}
            handleRemoveFormSubtitle={handleRemoveSubtitleForm}
            handleInputChangeSubtitle={handleSubtitleChange}
            handleDropChangeSubtitle={handleSubtitleDropChange}
            handleFilmStatusQuery={handleFilmStatusQuery}
            handleFilmVersionAdd={handleFilmVersionAdd}
            edit={false}
          />

          <img src={lineSplit} className={classes.lineBottom} />
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default CreateProductPageWrapper;
