import { Button, Grid, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useParams } from 'react-router-dom';

import LicenseWindow from 'components/licenses/forms/windows/LicenseWindow';
import Loading from 'components/loading/Loading';
import { RootState } from 'redux/store';
import { isBuyer } from 'services/authService';
import { CreateLicense } from 'services/educationalLicenseService';
import { getProduct } from 'services/productService';
import { getUser } from 'services/userService';
import dashboardStyle from 'styles/dashboard';
import licenseStyle from 'components/licenses/styles/LicenseStyles';

const CreateLTemplatePage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const [productTitle, setProductTitle] = useState('');
  const [licenseError, setLicenseError] = useState('');
  const [hasOrg, setHasOrg] = useState(false);

  const history = useHistory();
  const { id: productId } = useParams<{ id: string }>();
  const licenseTemplate = useSelector(
    (state: RootState) => state.licenseTemplate
  );
  const dashboardClasses = dashboardStyle();
  const licenseClasses = licenseStyle();

  const fetchData = useCallback(async () => {
    try {
      const product = await getProduct(productId, isBuyer());
      setProductTitle(product.title);
    } catch (error) {
      console.error(error);
    }

    try {
      const user = await getUser();
      setHasOrg(!!user.org);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  }, [productId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const createLicenseTemplate = useCallback(async () => {
    setLoading(true);
    try {
      const duration =
        licenseTemplate?.nonCommercialRights?.Educational?.duration;
      const price = licenseTemplate?.nonCommercialRights?.Educational?.price;
      if (duration && price) {
        await CreateLicense(productId, duration, price);
        history.push('/myBespokeLicenses');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [licenseTemplate, productId, history]);

  return (
    <div>
      <div className={dashboardClasses.headerWithoutMargin}>
        <Typography
          variant='h5'
          className={dashboardClasses.dashboardSubtitleBlue}
        >
          Create License
        </Typography>
      </div>

      {loading ? <Loading></Loading> : <></>}

      <Grid container>
        <Grid item xs={12} md={12}>
          {hasOrg && (
            <LicenseWindow
              licenseType='template'
              productId={productId}
              licenseId=''
              operationMode='create'
              setDisabledState={setDisabledBtn}
              loading={loading}
              classes={licenseClasses}
              setLoadingState={setLoading}
            />
          )}
          <div className={dashboardClasses.footerContainer}>
            <NavLink
              className={dashboardClasses.dashLinkBtn}
              to={`/movies/${productId}`}
            >
              <Button
                className={dashboardClasses.secondaryBtn}
                variant='outlined'
              >
                Back to Film
              </Button>
            </NavLink>

            <Button
              className={dashboardClasses.dashboardButton}
              variant='outlined'
              disableRipple
              disabled={!hasOrg || disabledBtn}
              onClick={createLicenseTemplate}
            >
              Save Template
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateLTemplatePage;
