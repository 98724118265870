import clsx from 'clsx';
import { useEffect, useState } from 'react';
// import { useImage } from 'react-image';
import { Img } from 'react-image';

import DefaultPoster from 'assets/images/default-image.jpg';
// import EditIcon from 'assets/images/edit-icon.svg';
import MovieIcon from '@mui/icons-material/Movie';
// import dashboardStyle from 'styles/dashboard';
import movieStyle from 'styles/movies';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { TypeContent } from 'models/enums';

interface Props {
  classes?: any;
  movie: boolean;
  title: string;
  poster?: string;
  director?: string;
  synopsis?: string;
  year?: number;
  duration?: number;
  genres?: any;
  genreList?: any;
  isSimpleView?: boolean;
  typeContent: any;
  nProducts?: number;
}

function ItemProduct(props: Props) {
  const {
    poster,
    title,
    director,
    movie,
    synopsis,
    genres,
    nProducts,
    year,
    duration,
    isSimpleView,
    typeContent,
  } = props;
  // const dashboadClasses = dashboardStyle();
  const movieClasses = movieStyle();
  const [srcImg] = useState(poster ? poster : DefaultPoster);

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sliceGenres =
    screenSize <= 1500 && genres !== undefined
      ? genres?.slice(0, 1)
      : screenSize >= 1800
        ? genres?.slice(0, 3)
        : genres?.slice(0, 2);

  return (
    <>
      {!isSimpleView ? (
        <Card className={movieClasses.cardStyles}>
          <CardMedia
            component='div'
            sx={{ position: 'relative', width: '30%', padding: '28px' }}
          >
            <div className={clsx(movieClasses.imageHover, 'movie-image-hover')}>
              <MovieIcon />
            </div>
            <Img
              src={[srcImg, DefaultPoster]}
              className={
                movie ? movieClasses.productImage : movieClasses.collectionImage
              }
            />
          </CardMedia>

          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <div className={movieClasses.productHeader}>
                <div className={movieClasses.productTitle}>{title}</div>
                {typeContent === TypeContent.FILMS ||
                typeContent === TypeContent.COLLECTIONFILMS ? (
                  <div className={movieClasses.productGenre}>
                    {sliceGenres
                      ? sliceGenres.map((genre: string, index: number) => (
                          <span key={index} className={movieClasses.genreItem}>
                            {genre}
                          </span>
                        ))
                      : ''}
                  </div>
                ) : (
                  <span className={movieClasses.genreItem}>
                    {nProducts} {nProducts === 1 ? 'film' : 'films'}
                  </span>
                )}
              </div>
              <div className={movieClasses.productDirector}>{director}</div>
              <div className={movieClasses.productDirector}>
                {year && duration
                  ? `${year} | ${duration} min`
                  : year
                    ? year
                    : duration
                      ? duration + ' min'
                      : ''}
              </div>
              <div className={movieClasses.productSinopsis}>{synopsis}</div>
            </CardContent>
          </Box>
        </Card>
      ) : (
        <div className={movieClasses.productItem}>
          <div className={movieClasses.productImgContainer}>
            <Img
              src={[srcImg, DefaultPoster]}
              className={
                movie ? movieClasses.productImage : movieClasses.collectionImage
              }
            />
            <div
              className={clsx(
                movieClasses.imageHoverSimple,
                'movie-image-hover'
              )}
            >
              <MovieIcon />
            </div>
          </div>

          <div className={movieClasses.productTitle}>{title}</div>
          {typeContent === TypeContent.COLLECTIONS && (
            <div className={movieClasses.productTitleSimple}>
              {nProducts} {nProducts === 1 ? 'film' : 'films'}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ItemProduct;
