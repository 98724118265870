import { Button, Link, Typography } from '@mui/material';
import { project } from 'project';
import React from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { Link as NavLink, useHistory, useParams } from 'react-router-dom';
import { verifyEmail } from 'services/authService';
import authStyle from 'styles/auth';

interface OwnProps {
  classes?: any;
}

const queryClient = new QueryClient();
const VerificationPageWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <VerificationPage {...props} />
    </QueryClientProvider>
  );
};

const VerificationPage: React.FC<OwnProps> = () => {
  const classes = authStyle();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { data } = useQuery(['verifyEmail', id], () => verifyEmail(id), {
    // onError: (error) => {
    //   console.log(error);
    //   history.push('/auth/login');
    // },
  });

  const header = data?.header || 'Account validated with success';
  const content =
    data?.content ||
    (project === 'mfh'
      ? 'Your account has been successfully validated! If you are a Tutor, please wait for admin confirmation; you will receive an email once your account is confirmed. If you are a Student, you can now log in to the platform. :)'
      : 'Your account has been successfully validated! You can now log in to the platform.');

  return (
    <div className={classes.authFormContainer}>
      <div className={classes.authFormBox}>
        <div className={classes.verificationContainer}>
          <Typography
            component='h1'
            variant='h5'
            className={classes.verificationTitle}
          >
            {header}
          </Typography>
          <p className={classes.verificationSubtitle}>{content}</p>
        </div>

        <Link
          className={classes.redirectLink}
          component={NavLink}
          to='/auth/login'
        >
          <Button
            fullWidth
            variant='contained'
            // color='default'
            className={classes.submit}
          >
            Go to Login Page
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default VerificationPageWrapper;
