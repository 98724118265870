import { Button } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ClausesWindowBody from 'components/licenses/forms/windows/ClausesWindowBody';
import {
  CommercialRightModel,
  CustomLicenseModel,
  RightWindowModel,
} from 'models';
import {
  CustomLicenseActions,
  LicenseTemplateActions,
  LicenseWindowActions,
} from 'redux/actions';
import { RootState } from 'redux/store';

import DoneIcon from '@mui/icons-material/Done';

interface OwnProps {
  classes?: any;
  rightName: string;
  subRightName: string;
  licenseType: string;
  operationMode: string;
}

interface DispatchProps {
  updateClausesCurrentOpenTabLW: (payload: any) => void;
  addClausesTabLW: (payload: any) => void;
  removeFromClausesLeftToBeChosenLW: (payload: any) => void;
  updateClausesLeftToBeChosenLW: (payload: any) => void;

  removeClauseEntryLW: (payload: any) => void;
  removeClauseEntryLT: (payload: any) => void;
  removeClauseEntryCL: (payload: any) => void;
}

interface StateProps {
  subRigntWindow: any;
  subRightCustom: any;
  subRightTemplate: any;
  customLicense: CustomLicenseModel;
  licenseTemplate: any;
}

type Props = StateProps & DispatchProps & OwnProps;

const ClausesWindow: React.FC<Props> = (props) => {
  useEffect(() => {
    const allowedClauses = getAllowedClauses();

    props.updateClausesLeftToBeChosenLW({
      rightName: props.rightName,
      subRightName: props.subRightName,
      leftToBeChosen: allowedClauses,
    });
  }, []);

  const getAllowedClauses = () => {
    const clauses = props.subRightTemplate.clauses;
    const keys = Object.keys(props.subRightTemplate.clauses);

    const allowedClauses = [];

    for (let i = 0; i < keys.length; i++) {
      if (clauses[keys[i]].allowed) allowedClauses.push(keys[i]);
    }

    if (props.operationMode === 'create') {
      if (props.licenseType === 'custom') {
        return allowedClauses;
      }
      // template form
      else return keys;
    } else {
      // View mode
      return allowedClauses;
    }
  };

  // Updates currently selected clause
  const selectClause = (tab: string) => {
    const {
      subRigntWindow,
      subRightTemplate,
      rightName,
      subRightName,
      addClausesTabLW,
      updateClausesCurrentOpenTabLW,
      removeFromClausesLeftToBeChosenLW,
    } = props;

    if (subRigntWindow.currentOpenTab !== tab) {
      if (subRigntWindow.currentOpenTab !== 'Add Clause') {
        resetClause(subRigntWindow.currentOpenTab);
      }

      updateClausesCurrentOpenTabLW({
        newTab: tab,
        rightName: rightName,
        subRightName: subRightName,
      });

      removeFromClausesLeftToBeChosenLW({
        newTab: tab,
        rightName: rightName,
        subRightName: subRightName,
      });

      if (props.licenseType === 'template')
        addClausesTabLW({
          newTab: tab,
          rightName: rightName,
          subRightName: subRightName,
          subRightTemplate: null,
        });

      if (props.licenseType === 'custom')
        addClausesTabLW({
          newTab: tab,
          rightName: rightName,
          subRightName: subRightName,
          subRightTemplate: subRightTemplate,
        });
    }
  };

  // Reset clause when selected option changes
  const resetClause = (clauseName: string) => {
    const {
      operationMode,
      licenseType,
      subRightTemplate,
      subRightCustom,
      rightName,
      subRightName,
      removeClauseEntryLW,
      removeClauseEntryLT,
      removeClauseEntryCL,
    } = props;

    const payload = {
      rightName: rightName,
      subRightName: subRightName,
      clauseName: clauseName,
    };

    removeClauseEntryLW(payload);

    // License Templates: If right tree was saved, DO NOT reset clauses data
    if (licenseType === 'template') {
      const clause = subRightTemplate.clauses[clauseName];
      if (clause.allowed) {
        return;
      }
    }

    // Custom Licenses: If right tree was saved, DO NOT reset clauses data
    if (licenseType === 'custom') {
      const clause = subRightCustom[clauseName];
      if (clause.saved) {
        return;
      }
    }

    if (operationMode === 'create') {
      if (licenseType === 'template')
        removeClauseEntryLT({
          rightName: rightName,
          subRightName: subRightName,
          clauseName: clauseName,
          licenseType: licenseType,
          operationMode: operationMode,
        });

      if (licenseType === 'custom') removeClauseEntryCL(payload);
    }
  };

  /**
   * Function to return the style of clause option
   * If clause was previously saved -> savedButton
   * If clause was selected by user -> activeButton
   */
  const selectedClauseStyle = (item: string) => {
    const {
      classes,
      licenseType,
      subRigntWindow,
      subRightTemplate,
      subRightCustom,
    } = props;

    const clauses = subRightTemplate.clauses;
    if (licenseType === 'template' && clauses[item]?.allowed) {
      return classes.savedButton;
    } else if (licenseType === 'custom' && subRightCustom[item]?.saved) {
      return classes.savedButton;
    }

    return subRigntWindow.currentOpenTab === item ? classes.activeButton : '';
  };

  // Function to check if tree path was previously saved
  const checkIfSaved = (item: string) => {
    const { licenseType, subRightTemplate, subRightCustom } = props;
    const clauses = subRightTemplate.clauses;

    if (licenseType === 'template' && clauses[item]?.allowed) {
      return true;
    } else if (licenseType === 'custom' && subRightCustom[item]?.saved) {
      return true;
    }
    return false;
  };

  // Clauses header: Render clauses options
  const ClausesHeader = () => {
    const { classes } = props;
    const clauses = getAllowedClauses();

    return (
      <div>
        <div className={clsx(classes.licenseSubtitle, classes.titleMargin)}>
          Choose Clauses
        </div>

        <div className={classes.optionsContainer}>
          {/* Normal Tab Buttons */}
          {clauses.map((item: any, index: any) => {
            return (
              <Button
                key={index}
                className={clsx(
                  classes.licenseButton,
                  selectedClauseStyle(item)
                )}
                onClick={() => {
                  selectClause(item);
                }}
              >
                {item}
                {checkIfSaved(item) ? (
                  <DoneIcon className={classes.doneIcon} />
                ) : (
                  <></>
                )}
              </Button>
            );
          })}
        </div>
      </div>
    );
  };

  // Clauses body: Render territories
  const ClausesBody = () => {
    const { rightName, subRightName, subRigntWindow } = props;
    const clauses = getAllowedClauses();

    let currentBody = <></>;

    if (clauses.indexOf(subRigntWindow.currentOpenTab) !== -1)
      currentBody = (
        <>
          <ClausesWindowBody
            rightName={rightName}
            subRightName={subRightName}
            clauseName={subRigntWindow.currentOpenTab}
            licenseType={props.licenseType}
            operationMode={props.operationMode}
          />
        </>
      );

    return <div>{currentBody}</div>;
  };

  return (
    <>
      <div>
        <ClausesHeader />
        <ClausesBody />
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const rightWindow = state.licenseWindow.openTabs.find(
    (element: RightWindowModel) => element.right === ownProps.rightName
  ) as CommercialRightModel;
  const subrightWindow =
    rightWindow !== undefined ? rightWindow[ownProps.subRightName] : null;

  return {
    customLicense: state.customLicense,
    licenseTemplate: state.licenseTemplate,
    subRigntWindow: subrightWindow,
    subRightTemplate:
      state.licenseTemplate.commercialRights[ownProps.rightName].subRights[
        ownProps.subRightName
      ],
    subRightCustom:
      state.customLicense.commercialRights[ownProps.rightName][
        ownProps.subRightName
      ],
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      updateClausesCurrentOpenTabLW:
        LicenseWindowActions.updateClausesCurrentOpenTabLW,
      addClausesTabLW: LicenseWindowActions.addClausesTabLW,
      removeFromClausesLeftToBeChosenLW:
        LicenseWindowActions.removeFromClausesLeftToBeChosenLW,
      updateClausesLeftToBeChosenLW:
        LicenseWindowActions.updateClausesLeftToBeChosenLW,
      removeClauseEntryLW: LicenseWindowActions.removeClauseEntryLW,

      removeClauseEntryLT: LicenseTemplateActions.removeClauseEntryLT,
      removeClauseEntryCL: CustomLicenseActions.removeClauseEntryCL,
    },
    dispatch
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ClausesWindow);
