import Grid from '@mui/material/Grid';
import React from 'react';

import dashboardStyle from 'styles/dashboard';

import { BaseProduct } from 'mapfilm-api';
import { ProductVersionForm } from 'models/ProductsModel';
import { deleteProductMediaSubtitle, updateProductMediaSubtitles } from 'services/productService';

import { Box, CircularProgress } from '@mui/material';
import { useMutation } from 'react-query';
import { PRIMARY_COLOR, SECONDARY_COLOR, THIRD_COLOR } from 'styles/colors';
import Swal from 'sweetalert2';
import SubtitlesForm from './SubtitlesForm';

interface Props {
  product: BaseProduct;
  classes?: any;
  productMediaSubtitles: any;
  handleAddFormSubtitle: any;
  handleRemoveFormSubtitle: any;
  handleInputChangeSubtitle: any;
  handleDropChangeSubtitle: any;
  handleFilmVersionAdd: any;
  handleFilmStatusQuery: (
    status: {
      index: number;
      status: string;
      id: string;
    }[]
  ) => void;
  edit?: boolean;
}

const AddSubtitles: React.FC<Props> = ({
  product,
  handleAddFormSubtitle,
  handleRemoveFormSubtitle,
  handleInputChangeSubtitle,
  handleDropChangeSubtitle,
  handleFilmVersionAdd,
  productMediaSubtitles,
  edit,
}) => {
  const classes = dashboardStyle();
  const mutationUploadSubtitles = useMutation(
    async ({
      vizziID,
      data,
    }: {
      vizziID: string;
      data: ProductVersionForm;
    }) => {
      if (vizziID === undefined) {
        throw new Error('No vizziID');
      }

      const { fileTypeSubtitles, stringFileSubtitles } = Array.isArray(
        productMediaSubtitles
      )
        ? productMediaSubtitles.reduce(
            (acc, sub: any) => {
              const filesArray = Array.isArray(sub.files)
                ? sub.files
                : [sub.files];

              if (filesArray.every((file: any) => file instanceof File)) {
                acc.fileTypeSubtitles.push(sub);
              } else {
                acc.stringFileSubtitles.push(sub);
              }

              return acc;
            },
            { fileTypeSubtitles: [] as any[], stringFileSubtitles: [] as any[] }
          )
        : { fileTypeSubtitles: [], stringFileSubtitles: [] };

      const subtitleFiles =
        fileTypeSubtitles?.flatMap((sub: any) => sub.files) ?? [];

      const subtitleLanguages =
        fileTypeSubtitles?.map((sub: any) => sub.language).join(',') ?? '';

      const oldProcessedStringFiles = stringFileSubtitles.map((sub: any) => ({
        language: sub.language,
        url: sub.files,
      }));

      if (subtitleFiles.length > 0) {
        return updateProductMediaSubtitles(
          vizziID,
          subtitleFiles,
          subtitleLanguages,
          oldProcessedStringFiles
        );
      }
    },
    {
      mutationKey: 'updateMediaSubtitles',
      onSuccess: () => {
        Swal.fire({
          text: 'Subtitles uploaded successfully.',
          icon: 'success',
          iconColor: SECONDARY_COLOR,
          showConfirmButton: false,
        });
      },
      onError: () => {
        Swal.fire({
          text: 'Failed to upload subtitles.',
          icon: 'error',
          iconColor: SECONDARY_COLOR,
          showConfirmButton: false,
        });
      },
    }
  );

  const handleUpdateSubtitlesClick = () => {
    product.versions &&
      mutationUploadSubtitles.mutate({
        vizziID: product.versions[0]._id,
        data: productMediaSubtitles,
      });
  };

  const mutationDeleteSubtitle = useMutation(
    async ({ vizziID, subtitleToDelete, index }: { vizziID: string; subtitleToDelete: { language: string; url: string }[]; index: number }) => {
      return deleteProductMediaSubtitle(vizziID, subtitleToDelete);
    },
    {
      onSuccess: (data, variables) => {
        Swal.fire({
          text: 'Subtitle deleted successfully.',
          icon: 'success',
          iconColor: SECONDARY_COLOR,
          showConfirmButton: false,
          timer: 1500,
        });
        handleRemoveFormSubtitle(variables.index);
      },
      onError: () => {
        Swal.fire({
          text: 'Failed to delete subtitle.',
          icon: 'error',
          iconColor: SECONDARY_COLOR,
          showConfirmButton: false,
          timer: 1500,
        });
      },
    }
  );

  const handleRemoveSubtitle = (index: number, formData: ProductVersionForm) => {
    if (
      formData.language === '' ||
      (!formData.files || (typeof formData.files === 'string' && formData.files === ''))
    ) {
      handleRemoveFormSubtitle(index);
      return;
    }
    Swal.fire({
      text: 'Are you sure you want delete the subtitle?',
      icon: 'warning',
      iconColor: SECONDARY_COLOR,
      showCancelButton: true,
      confirmButtonColor: SECONDARY_COLOR,
      cancelButtonColor: PRIMARY_COLOR,
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        if (formData.files instanceof File) {
          handleRemoveFormSubtitle(index);
        } else if (typeof formData.files === 'string' && product.versions && formData.language) {
          const vizziID = product?.versions[0]._id;
          mutationDeleteSubtitle.mutate({
            vizziID,
            subtitleToDelete: [{ language: formData.language, url: formData.files }],
            index,
          });
        } else{
          handleRemoveFormSubtitle(index);
        }
      }
    });
  };


  return (
    <React.Fragment>
      <div>
        {edit === true ? (
          <button
            className={classes.buttonUploadVid}
            type='button'
            onClick={handleUpdateSubtitlesClick}
            disabled={mutationUploadSubtitles.isLoading}
          >
            {mutationUploadSubtitles.isLoading ? (
              <Box sx={{ display: 'inline' }}>
                <CircularProgress
                  sx={{
                    color: THIRD_COLOR,
                    width: '25px !important',
                    height: '25px !important',
                  }}
                />
              </Box>
            ) : (
              <>Update Subtitles</>
            )}
          </button>
        ) : (
          <></>
        )}

        <Grid container>
          <Grid item container sm={12} alignItems='flex-start'>
            <Grid item container spacing={4}>
              <Grid item sm={12}>
                {productMediaSubtitles.map(
                  (formData: ProductVersionForm, index: number) => (
                    <SubtitlesForm
                      key={index}
                      product={product}
                      formData={formData}
                      index={index}
                      classes={classes}
                      handleInputChange={handleInputChangeSubtitle}
                      handleDropChange={handleDropChangeSubtitle}
                      handleFilmVersionAdd={handleFilmVersionAdd}
                      handleRemoveForm={handleRemoveSubtitle}
                    />
                  )
                )}
              </Grid>
            </Grid>

            <button
              className={classes.dashboardButtonAddMedia}
              type='submit'
              onClick={handleAddFormSubtitle}
            >
              + Add manual subtitles
            </button>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default AddSubtitles;
