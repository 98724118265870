import { Grid } from '@mui/material';
import UploadIcon from 'assets/icons/uploadIcon.svg';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import dropzoneStyle from './DropzoneStyles';

interface SubtitleDropzoneProps {
  propFile: any;
  setPropFile: (acceptedFile: File) => void;
  classes?: any;
  disable?: boolean;
}

const SubtitleDropzone: React.FC<SubtitleDropzoneProps> = ({
  propFile,
  setPropFile,
  disable,
}) => {
  const classes = dropzoneStyle();
  const [fileName, setFileName] = useState<string | null>(null);

  useEffect(() => {
    if (propFile) {
      setFileName(propFile.name ? propFile.name : propFile);
    }
  }, [propFile]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/vtt': ['.vtt'],
      'text/srt': ['.srt'],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const selectedFile = acceptedFiles[0];
        setFileName(selectedFile.name);
        setPropFile(selectedFile);
      }
    },
    disabled: disable,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {fileName ? (
          <div
            {...getRootProps()}
            className={`${classes.labelsContainer} ${
              disable ? classes.disabledContainer : ''
            }`}
          >
            <input {...getInputProps()} />
            <div className={classes.pContainerSub}>
              <p className={classes.fileName}>{fileName}</p>
            </div>
          </div>
        ) : (
          <div {...getRootProps()} className={classes.labelsContainer}>
            <input {...getInputProps()} />
            <div className={classes.pContainer}>
              <img src={UploadIcon} alt='Upload Icon' />
              <p className={classes.pStyle}>.srt or .vtt</p>
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default SubtitleDropzone;
