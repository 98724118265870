import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Loading from 'components/loading/Loading';
import { commercialRightsDB, nonCommercialRightsDB } from 'database/rightsDB';
import { NavLink } from 'react-router-dom';
import { getAllBespokeLicenses } from 'services/educationalLicenseService';
import lListStyle from 'pages/licenses/styles/LListStyles';
import dashboardStyle from 'styles/dashboard';

const LTlist: React.FC = () => {
  const [templates, setTemplates] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dashboardClasses = dashboardStyle();
  const lListClasses = lListStyle();

  useEffect(() => {
    const fetchBespokeLicenses = async () => {
      try {
        const value = await getAllBespokeLicenses();
        setTemplates(value);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchBespokeLicenses();
  }, []);

  const getRights = (index: number) => {
    const temp: string[] = [];
    const value: any = templates[index];

    const cRights = Object.keys(commercialRightsDB);
    cRights.forEach((right) => {
      if (value.commercialRights?.[right]?.allowed || value.rights?.[right]?.allowed) {
        temp.push(right);
      }
    });

    const ncRights = Object.keys(nonCommercialRightsDB);
    ncRights.forEach((right) => {
      if (value.nonCommercialRights?.[right]?.allowed) {
        temp.push(right);
      }
    });

    return temp.join(', ');
  };

  return (
    <>
      <div className={dashboardClasses.headerContainer}>
        <Typography className={dashboardClasses.dashboardTitle} variant='h4'>
          My Bespoke Licenses
        </Typography>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <TableContainer className={lListClasses.tableContainer} component={Paper}>
          <Table className={lListClasses.table}>
            <TableHead className={lListClasses.tableHead}>
              <TableRow>
                <TableCell>Film Title</TableCell>
                <TableCell>Licensor</TableCell>
                <TableCell>License</TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={lListClasses.tableBody}>
              {templates.length === 0 ? (
                <TableRow>
                  <TableCell className={lListClasses.noLicenses} colSpan={3}>
                    No entries were found.
                  </TableCell>
                </TableRow>
              ) : (
                templates.map((row: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>
                      <NavLink className={lListClasses.downloadLink} to={`/movies/${row.TitleID}`}>
                        {row.Title}
                      </NavLink>
                    </TableCell>
                    <TableCell>{row.Licensor}</TableCell>
                    <TableCell>
                      <NavLink className={lListClasses.downloadLink} to={`/myBespokeLicenses/${row.TitleID}`}>
                        Educational
                      </NavLink>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/*<List>
          {templates.map((item: any, index: any) => {
              return (
                  <NavLink key={index} to={'/myBespokeLicenses/' + item.licenseTemplateId} >
                      <ListItem button >
                          <ListItemText primary={getRights(index)} />
                          <ListItemText primary={item.creationDate} />
                      </ListItem>
                  </NavLink>
              )
          })}
      </List>*/}
    </>
  );
};

export default LTlist;
