import { convertHtmlToReact } from '@hedgedoc/html-to-react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import Loading from 'components/loading/Loading';
import { useEffect, useRef, useState } from 'react';
import {
  getContractPreview,
  PrepareLicensePurchase,
} from 'services/educationalLicenseService';

import CloseIcon from '@mui/icons-material/Close';

import dashboardStyle from 'styles/dashboard';
import cStyle from './CStyles';

interface OwnProps {
  classes?: any;
  history?: any;
  collectionId: string;
  openPopup: boolean;
  dialogTitle: string;
  setOpenPopup: (value: boolean) => void | undefined;
}

function ContractPreview(props: OwnProps) {
  const dashboardClasses = dashboardStyle();
  const cStyleClasses = cStyle();

  const { openPopup } = props;
  const [contractHTML, setContractHTML] = useState('<div></div>');
  const [isLoading, setLoading] = useState(true);
  const [isChecked, setChecked] = useState(false);
  const mountedRef = useRef(true);

  const buyCollection = async (collectionId: string) => {
    setLoading(true);

    await PrepareLicensePurchase(collectionId).then(
      (value) => {
        // On run
        setLoading(false);
        props.history.push('/myPendingLicenses');
      },
      (reason) => {
        // on fail
        setLoading(false);
        console.log(reason);
      }
    );
  };

  useEffect(() => {
    if (!props.collectionId) return;
    getContractPreview(props.collectionId).then((value: string) => {
      if (!mountedRef.current) return;
      setContractHTML(value);
      setLoading(false);
    });

    return () => {
      mountedRef.current = false;
    };
  }, [props.collectionId]);

  const onClose = (event: any, reason: string) => {
    if (reason !== 'backdropClick') {
      props.setOpenPopup(false);
    }
  };

  return (
    <Dialog open={openPopup} onClose={onClose} maxWidth='lg'>
      <DialogContent className={cStyleClasses.popupContainer}>
        <div className={cStyleClasses.popupHeader}>
          <Typography
            variant='h5'
            className={dashboardClasses.dashboardSubtitleBlue}
          >
            {props.dialogTitle}
          </Typography>

          <IconButton
            className={cStyleClasses.closeButton}
            onClick={() => props.setOpenPopup(false)}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div
          className={
            isLoading
              ? cStyleClasses.hiddenContainer
              : cStyleClasses.contractContainer
          }
        >
          <div className={isLoading ? 'hidden-contract' : 'visible-contract'}>
            {convertHtmlToReact(contractHTML)}
          </div>

          {isLoading ? (
            <div className={cStyleClasses.loadingContent}>
              <Loading />
            </div>
          ) : (
            <></>
          )}
        </div>

        <FormControlLabel
          className={cStyleClasses.checkboxContainer}
          label='I agree with the terms of the contract as set out above.'
          control={
            <Checkbox
              checked={isChecked}
              onChange={(e) => setChecked(e.target.checked)}
              name='sign-license'
              color='primary'
            />
          }
        />

        <div className={cStyleClasses.contractOptions}>
          <Button
            className={cStyleClasses.closeButtonFooter}
            variant='outlined'
            onClick={() => props.setOpenPopup(false)}
          >
            Decline
          </Button>
          <Button
            className={dashboardClasses.dashboardButton}
            variant='outlined'
            onClick={() => buyCollection(props.collectionId)}
            disabled={isChecked ? false : true}
          >
            Accept
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ContractPreview;
