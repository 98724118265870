import { FormLabel, Grid } from '@mui/material';
import React from 'react';

import MediaForm from 'pages/products/addFilms/AddMediaVersion';
import MediaVODForm from 'pages/products/addFilms/AddMediaVOD';

import dashboardStyle from 'styles/dashboard';

import { BaseProduct, LibraryProductVersion } from 'mapfilm-api';
import { ProductVersionForm } from 'models/ProductsModel';

import { project } from 'project';
import { useQuery } from 'react-query';
import { getProductMedia } from 'services/productService';
import AddSubtitles from './AddSubtitles';

interface Props {
  product: BaseProduct;
  classes?: any;
  handleAddForm: any;
  handleRemoveForm: any;
  handleInputChange: any;
  handleDropChange: any;
  handleFilmVersionAdd: any;

  productMediaSubtitles?: any;
  handleAddFormSubtitle?: () => void;
  handleRemoveFormSubtitle?: any;
  handleInputChangeSubtitle?: any;
  handleDropChangeSubtitle?: any;

  handleFilmStatusQuery: (
    status: {
      index: number;
      status: string;
      id: string;
    }[]
  ) => void;
  productMedia: ProductVersionForm[];
  edit: boolean;
}

const AddMedia: React.FC<Props> = ({
  product,
  productMedia,
  handleAddForm,
  handleRemoveForm,
  handleInputChange,
  handleDropChange,
  productMediaSubtitles,
  handleAddFormSubtitle,
  handleRemoveFormSubtitle,
  handleInputChangeSubtitle,
  handleDropChangeSubtitle,
  handleFilmStatusQuery,
  handleFilmVersionAdd,
  edit,
}) => {
  const classes = dashboardStyle();
  const hasCompletedMedia = productMedia.every(
    (mediaItem: ProductVersionForm) => mediaItem.status === 'COMPLETED'
  );
  const { isLoading, isError, data, error } = useQuery(
    ['videosToUpload', productMedia],
    async () => {
      //create uuidList with all uuid from media that are not undefined
      const uuidList = productMedia
        .map((media: ProductVersionForm) => media.uuid)
        .filter((uuid: string | undefined) => uuid !== undefined);
      if (uuidList.length !== 0) {
        const media = await getProductMedia(uuidList as string[]);

        productMedia.forEach((mediaItem: ProductVersionForm) => {
          const hasMedia = media.find(
            (item: LibraryProductVersion) => item.uuid === mediaItem.uuid
          );
          if (!hasMedia) {
            if (mediaItem.uuid) mediaItem.status = 'UPLOADING';
          } else {
            mediaItem.status = hasMedia.status;
            mediaItem.version = hasMedia.name;
            mediaItem.vizziID = hasMedia.id;
          }
        });
        return productMedia;
      }
    },
    {
      enabled: productMedia.length > 0,
      refetchInterval: hasCompletedMedia ? false : 5000,
    }
  );

  React.useEffect(() => {
    if (!isLoading && !isError && data) {
      const updatedProductMedia = data.map((media: any, index: number) => {
        return {
          index: index,
          status: media.status,
          id: media.vizziID,
        };
      });

      handleFilmStatusQuery(updatedProductMedia);
    }
  }, [data, isLoading, isError]);

  console.log('prod media', productMedia);

  return (
    <React.Fragment>
      <div>
        <Grid container>
          <Grid item sm={12} alignItems='flex-start'>
            <Grid item sm={12}>
              <FormLabel
                className={classes.labelBlack}
                style={{ marginTop: '30px' }}
                component='legend'
              >
                MEDIA
              </FormLabel>

              <Grid item container spacing={4}>
                <Grid item sm={project !== 'scene' ? 12 : 6}>
                  {project !== 'scene'
                    ? productMedia.map(
                        (formData: ProductVersionForm, index: number) => (
                          <MediaForm
                            key={index}
                            product={product}
                            formData={formData}
                            index={index}
                            classes={classes}
                            handleInputChange={handleInputChange}
                            handleDropChange={handleDropChange}
                            handleFilmVersionAdd={handleFilmVersionAdd}
                            handleRemoveForm={handleRemoveForm}
                          />
                        )
                      )
                    : productMedia.length > 0 && (
                        <MediaVODForm
                          key={0}
                          product={product}
                          productMediaSubtitles={productMediaSubtitles}
                          formData={productMedia[0]}
                          index={0}
                          classes={classes}
                          handleInputChange={handleInputChange}
                          handleDropChange={handleDropChange}
                          handleFilmVersionAdd={handleFilmVersionAdd}
                          handleRemoveForm={handleRemoveForm}
                          edit={edit}
                        />
                      )}
                </Grid>
                <Grid
                  item
                  sm={project !== 'scene' ? 1 : 6}
                  style={
                    edit === false ? { marginTop: '56px' } : { marginTop: '0' }
                  }
                >
                  {productMedia.length > 0 && project === 'scene' && (
                    <AddSubtitles
                      product={product}
                      classes={classes}
                      productMediaSubtitles={productMediaSubtitles}
                      handleAddFormSubtitle={handleAddFormSubtitle}
                      handleRemoveFormSubtitle={handleRemoveFormSubtitle}
                      handleInputChangeSubtitle={handleInputChangeSubtitle}
                      handleDropChangeSubtitle={handleDropChangeSubtitle}
                      handleFilmStatusQuery={handleFilmStatusQuery}
                      handleFilmVersionAdd={handleFilmVersionAdd}
                      edit={edit}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            {productMedia.length > 0 && project === 'scene' ? (
              <></>
            ) : (
              <button
                className={classes.dashboardButtonAddMedia}
                type='submit'
                onClick={handleAddForm}
              >
                + Add media
              </button>
            )}
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default AddMedia;
