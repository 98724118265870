import licenseStyle from 'components/licenses/styles/LicenseStyles';
import {
  isAfter,
  isBefore,
  isSameDay,
  isToday,
  isWithinInterval,
} from 'date-fns';
import { CustomTerritoryModel, PeriodModel } from 'models';
import { TemplateTerritoryModel } from 'models/LicenseTemplateModel';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CustomLicenseActions, LicenseTemplateActions } from 'redux/actions';
import { RootState } from 'redux/store';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { ValueType } from 'rsuite/lib/DateRangePicker';

const { beforeToday, combine } = DateRangePicker;

interface OwnProps {
  classes?: any;
  rightName: string;
  subRightName: string;
  clauseName: string;
  territoryId: string;
  territoryName: string;
  periodId: string;
  licenseType: string;
}

interface DispatchProps {
  updateBlockedPeriodLT: (payload: any) => void;
  removeBlockedPeriodLT: (payload: any) => void;
  updatePeriodCL: (payload: any) => void;
  removePeriodCL: (payload: any) => void;
}

interface StateProps {
  period: PeriodModel;
  blockedPeriods: PeriodModel[];
  periods: PeriodModel[];
}

type Props = StateProps & DispatchProps & OwnProps;

const PeriodComponent: React.FC<Props> = (props) => {
  const [selectedDates, setSelectedDates] = useState([] as Date[]);
  const [updated, setUpdated] = useState(false as boolean);
  const classes = licenseStyle();

  const updatePeriod = (newPeriod: PeriodModel) => {
    // Update Period
    if (props.licenseType === 'template') {
      props.updateBlockedPeriodLT({
        rightName: props.rightName,
        subRightName: props.subRightName,
        clauseName: props.clauseName,
        territoryId: props.territoryId,
        periodId: props.periodId,
        period: newPeriod,
      });
    } // else update normal license period
    if (props.licenseType === 'custom') {
      /*if(this.props.rightName === 'Educational') {
                this.props.updateEduPeriodCL({
                    rightName: this.props.rightName,
                    period: newPeriod
                })
            }
            else {*/
      props.updatePeriodCL({
        rightName: props.rightName,
        subRightName: props.subRightName,
        clauseName: props.clauseName,
        territoryId: props.territoryId,
        periodId: props.periodId,
        period: newPeriod,
      });
      //}
    }
  };

  const parseDate = (date: string | Date): Date => {
    if (!(date instanceof Date)) return new Date(date.toString());
    else return date;
  };

  const dateToString = (date: Date | string): string => {
    if (date instanceof Date) return date.toString();
    else return date;
  };

  useEffect(() => {
    const { period } = props;
    if (!(period === null || typeof period === 'undefined')) {
      if (!(period.start === '' || period.end === ''))
        setSelectedDates([parseDate(period.start), parseDate(period.end)]);
    }
  }, []);

  const addDate = (newDate: Date) => {
    setSelectedDates([...selectedDates, parseDate(newDate)]);
  };

  /* Triggers when a change is done to the state */

  const isWithinIntervals = (date: Date) => {
    const { blockedPeriods } = props;

    for (let i = 0; i < blockedPeriods.length; i++) {
      const start = parseDate(blockedPeriods[i].start);
      const end = parseDate(blockedPeriods[i].end);

      if (
        isWithinInterval(date, { start: start, end: end }) ||
        isSameDay(date, end) ||
        isSameDay(date, start)
      ) {
        return true;
      }
    }
    if (props.licenseType === 'custom') {
      for (let i = 0; i < props.periods.length; i++) {
        const start = parseDate(props.periods[i].start);
        const end = parseDate(props.periods[i].end);

        if (
          isWithinInterval(date, { start: start, end: end }) ||
          isSameDay(date, end) ||
          isSameDay(date, start)
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const getFirstBlockedDate = () => {
    const { blockedPeriods } = props;
    //procurar o blocked periodo mais perto e bloquear a partir daí
    let firstBlockedDate = new Date(8640000000000000); //Infinity

    for (let i = 0; i < blockedPeriods.length; i++) {
      // startblockedDate tem de ser depois da 1a selecionada
      const start = parseDate(blockedPeriods[i].start);

      if (isAfter(start, selectedDates[0])) {
        if (isBefore(start, firstBlockedDate)) {
          firstBlockedDate = start;
        }
      }
    }

    if (props.licenseType === 'custom') {
      for (let i = 0; i < props.periods.length; i++) {
        // startblockedDate tem de ser depois da 1a selecionada
        const start = parseDate(props.periods[i].start);

        if (isAfter(start, selectedDates[0])) {
          if (isBefore(start, firstBlockedDate)) {
            firstBlockedDate = start;
          }
        }
      }
    }

    return firstBlockedDate;
  };

  const getLastBlockedDate = () => {
    const { blockedPeriods } = props;
    let lastBlockedDate = new Date(); //Today

    for (let i = 0; i < blockedPeriods.length; i++) {
      const end = parseDate(blockedPeriods[i].end);

      if (isBefore(end, selectedDates[0])) {
        if (isAfter(end, lastBlockedDate)) {
          lastBlockedDate = end;
        }
      }
    }

    if (props.licenseType === 'custom') {
      for (let i = 0; i < props.periods.length; i++) {
        const end = parseDate(props.periods[i].end);

        if (isBefore(end, selectedDates[0])) {
          if (isAfter(end, lastBlockedDate)) {
            lastBlockedDate = end;
          }
        }
      }
    }
    return lastBlockedDate;
  };

  useEffect(() => {
    if (selectedDates.length > 2) {
      // remove from origin state
      removePeriodFromState();
      setSelectedDates([selectedDates[2]]);
      setUpdated(false);
    }
    //verificar se existe no redux state
  }, []);

  const sortState = () => {
    if (isBefore(selectedDates[1], selectedDates[0])) {
      setSelectedDates([selectedDates[1], selectedDates[0]]);
    }
  };

  const addPeriodToState = () => {
    let newPeriod: PeriodModel;

    if (isBefore(selectedDates[1], selectedDates[0]))
      newPeriod = {
        periodId: props.periodId,
        start: dateToString(selectedDates[1]),
        end: dateToString(selectedDates[0]),
      };
    else
      newPeriod = {
        periodId: props.periodId,
        start: dateToString(selectedDates[0]),
        end: dateToString(selectedDates[1]),
      };

    updatePeriod(newPeriod);
  };

  const removePeriodFromState = () => {
    if (props.licenseType === 'template') {
      props.removeBlockedPeriodLT({
        rightName: props.rightName,
        subRightName: props.subRightName,
        clauseName: props.clauseName,
        territoryId: props.territoryId,
        blockedPeriodId: props.periodId,
      });
    } // else update normal license period
    if (props.licenseType === 'custom') {
      props.removePeriodCL({
        rightName: props.rightName,
        subRightName: props.subRightName,
        clauseName: props.clauseName,
        territoryId: props.territoryId,
        blockedPeriodId: props.periodId,
      });
    }
  };

  const getSelectedDate = () => {
    const { blockedPeriods, periodId } = props;

    let ret: ValueType = [];

    if (props.licenseType === 'template') {
      if (blockedPeriods.length !== 0) {
        const bp = blockedPeriods.find(
          (element) => element.periodId === periodId
        );

        if (bp !== undefined) ret = [parseDate(bp.start), parseDate(bp.end)];
      }
    }
    if (props.licenseType === 'custom') {
      if (props.periods.length !== 0) {
        const p = props.periods.find(
          (element) => element.periodId === periodId
        );

        if (p !== undefined) ret = [parseDate(p.start), parseDate(p.end)];
      }
    }
    return ret;
  };

  // Ao bloquear todas as datas before 1st selection, resolve-se possiveis problemas
  return (
    <>
      <DateRangePicker
        className={classes.blockedPeriodStyle}
        style={{
          width: 280,
        }}
        //https://rsuitejs.com/components/date-range-picker/
        //showOneCalendar
        ranges={[]}
        //placeholder={<input type="date"></input>}
        disabledDate={combine(beforeToday(), (date: Date) => {
          if (isToday(date)) return true;

          const firstBlockedDate = getFirstBlockedDate();
          const lastBlockedDate = getLastBlockedDate();
          if (selectedDates.length === 0 || selectedDates.length === 2)
            return isWithinIntervals(date);
          else
            return (
              isAfter(date, firstBlockedDate) ||
              isSameDay(date, firstBlockedDate) ||
              isBefore(date, lastBlockedDate) ||
              isSameDay(date, lastBlockedDate)
            );
        })}
        onSelect={(date) => addDate(new Date(date))}
        onOk={() => {
          sortState();
          addPeriodToState();
        }}
        value={getSelectedDate()}
        cleanable={false}
        onClean={() => {
          if (selectedDates.length === 2) {
            removePeriodFromState();
          }
          setSelectedDates([]);
        }}
        placement='auto'
      />
    </>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const emptyPeriod: PeriodModel = {
    periodId: ownProps.periodId,
    start: '',
    end: '',
  }; // empty date
  let period: PeriodModel = emptyPeriod;
  let blockedPeriods: PeriodModel[] = [],
    periods: PeriodModel[] = [];

  const templateTerritories =
    state.licenseTemplate.commercialRights[ownProps.rightName].subRights[
      ownProps.subRightName
    ].clauses[ownProps.clauseName].territories;
  const customTerritories =
    state.customLicense.commercialRights[ownProps.rightName][
      ownProps.subRightName
    ][ownProps.clauseName].territories;
  if (ownProps.licenseType === 'template') {
    const templateTerritory = templateTerritories.find(
      (element: TemplateTerritoryModel) =>
        element.territoryId === ownProps.territoryId
    );

    const bpTemplate =
      templateTerritory !== undefined ? templateTerritory.blockedPeriods : [];

    const templatePeriod = bpTemplate.find(
      (element: PeriodModel) => element.periodId === ownProps.periodId
    );
    period = templatePeriod !== undefined ? templatePeriod : emptyPeriod;
    blockedPeriods = bpTemplate;
    periods = [];
  }

  // State Getter for Customization Page
  if (ownProps.licenseType === 'custom') {
    const customTerritory = customTerritories.find(
      (element: CustomTerritoryModel) =>
        element.territoryId === ownProps.territoryId
    );
    periods = customTerritory !== undefined ? customTerritory.periods : periods;
    const bpCustom =
      customTerritory !== undefined ? customTerritory.periods : [];

    const customPeriod = bpCustom.find(
      (element: PeriodModel) => element.periodId === ownProps.periodId
    );
    period = customPeriod !== undefined ? customPeriod : emptyPeriod;

    if (ownProps.territoryName === '' || ownProps.territoryName === undefined)
      blockedPeriods = [];
    else {
      const templateTerritory = templateTerritories.find(
        (element: TemplateTerritoryModel) =>
          element.territoryName === ownProps.territoryName
      );
      blockedPeriods =
        templateTerritory !== undefined ? templateTerritory.blockedPeriods : [];
    }
  }

  return {
    period: period,
    blockedPeriods: blockedPeriods,
    periods: periods,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      updateBlockedPeriodLT: LicenseTemplateActions.updateBlockedPeriodLT,
      removeBlockedPeriodLT: LicenseTemplateActions.removeBlockedPeriodLT,

      updatePeriodCL: CustomLicenseActions.updatePeriodCL,
      removePeriodCL: CustomLicenseActions.removePeriodCL,
    },
    dispatch
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PeriodComponent);
