import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Button } from 'rsuite';
import { isBuyer, isSeller } from 'services/authService';

import AddIcon from '@mui/icons-material/Add';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import dashboardStyle from 'styles/dashboard';
import movieStyle from 'styles/movies';

import { TypeContent } from 'models/enums';
import DownloadsCounter from 'pages/product/DownloadsCounter';
import { project } from 'project';

const LibraryHeader = ({
  searchQuery,
  setSearchQuery,
  productsLength,
  typeContent,
  collectionInfo,
}: any) => {
  const dashboadClasses = dashboardStyle();
  const movieClasses = movieStyle();

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchQuery(event.target.value);
  };

  return (
    <>
      {typeContent === TypeContent.COLLECTIONFILMS && (
        <NavLink to='/collections' className={dashboadClasses.backLink}>
          <NavigateBeforeIcon className={dashboadClasses.backIcon} />
          BACK
        </NavLink>
      )}
      <div
        className={`${dashboadClasses.headerContainer} ${dashboadClasses.marginHeaderContainer}`}
      >
        <Typography component='h4' className={dashboadClasses.dashboardTitle}>
          {isSeller() ? (
            <>
              {typeContent === TypeContent.FILMS
                ? 'My Films '
                : typeContent === TypeContent.COLLECTIONFILMS
                  ? `${collectionInfo.name} `
                  : 'My Collections '}
              <span className={movieClasses.numberResults}>
                ({productsLength} {productsLength === 1 ? 'result' : 'results'})
              </span>
            </>
          ) : (
            <>
              {typeContent === TypeContent.FILMS
                ? 'Films '
                : typeContent === TypeContent.COLLECTIONFILMS
                  ? `${collectionInfo.name} `
                  : 'Collections '}
              <span className={movieClasses.numberResults}>
                ({productsLength} {productsLength === 1 ? 'result' : 'results'})
              </span>
            </>
          )}
        </Typography>

        <TextField
          variant='outlined'
          value={searchQuery}
          onChange={(e: any) => handleSearch(e)}
          className={movieClasses.searchField}
          placeholder='Search'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        {isSeller() ? (
          <div
            className={`${dashboadClasses.buttonsContainer} ${dashboadClasses.buttonsOrg}`}
          >
            {typeContent === TypeContent.FILMS ||
            typeContent === TypeContent.COLLECTIONFILMS ? (
              <>
                <NavLink
                  className={dashboadClasses.dashLinkBtn}
                  style={{ marginRight: '0' }}
                  to={
                    typeContent === TypeContent.COLLECTIONFILMS
                      ? `/collection/${collectionInfo.id}/movie/create`
                      : '/movie/create'
                  }
                >
                  <Button
                    className={dashboadClasses.dashboardButton}
                    variant='outlined'
                  >
                    <span className='btn-txt'>Add Film</span>{' '}
                    <AddIcon className={dashboadClasses.btnIcon} />
                  </Button>
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  className={dashboadClasses.dashLinkBtn}
                  style={{ marginRight: '0' }}
                  to='/collection/create'
                >
                  <Button
                    className={dashboadClasses.dashboardButton}
                    variant='outlined'
                  >
                    <span className='btn-txt'>Add Collection</span>{' '}
                    <AddIcon className={dashboadClasses.btnIcon} />
                  </Button>
                </NavLink>
              </>
            )}
          </div>
        ) : (
          ''
        )}
        {isBuyer() && project === 'mfh' ? <DownloadsCounter /> : ''}
      </div>
    </>
  );
};

export default LibraryHeader;
