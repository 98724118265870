import DescriptionIcon from '@mui/icons-material/Description';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Loading from 'components/loading/Loading';

import React, { useState } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { isSeller } from 'services/authService';
import { getSignedLicenses } from 'services/educationalLicenseService';

import ContractModal from './ContractModal';

import lListStyle from 'pages/licenses/styles/LListStyles';
import dashboardStyle from 'styles/dashboard';

interface Props {
  classes?: any;
}

const queryClient = new QueryClient();

const CLlistMFHWrapper: React.FC<Props> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CLlistMFH {...props} />
    </QueryClientProvider>
  );
};

const CLlistMFH: React.FC<Props> = () => {
  const dashboardClasses = dashboardStyle();
  const lListClasses = lListStyle();

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<string | null>(null);

  const { data: licenses, isLoading } = useQuery(
    ['signedLicenses'],
    getSignedLicenses
  );

  const handleOpenModal = (row: any) => {
    setSelectedRow(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRow(null);
  };

  return (
    <>
      <div className={dashboardClasses.headerContainer}>
        <Typography
          className={dashboardClasses.dashboardTitle}
          variant='h4'
          sx={{ fontWeight: '700 !important' }}
        >
          My Signed Licenses
        </Typography>
      </div>

      {isLoading ? (
        <Loading />
      ) : (
        <TableContainer
          className={lListClasses.tableContainer}
          component={Paper}
        >
          <Table className={lListClasses.table}>
            <TableHead className={lListClasses.tableHead}>
              <TableRow>
                <TableCell>
                  {isSeller() ? 'Signed Institution' : 'Organization'}
                </TableCell>
                <TableCell>Contract</TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={lListClasses.tableBody}>
              {licenses?.length === 0 ? (
                <TableRow>
                  <TableCell className={lListClasses.noLicenses} colSpan={5}>
                    No entries were found.
                  </TableCell>
                </TableRow>
              ) : (
                licenses?.map((row: any) => (
                  <TableRow key={row.licenseId}>
                    <TableCell component='th' scope='row'>
                      {isSeller() ? row.licensee : row.productTitle}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenModal(row)}>
                        <DescriptionIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {selectedRow && (
        <ContractModal
          open={openModal}
          row={selectedRow}
          handleClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default CLlistMFHWrapper;
