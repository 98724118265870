import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { PRIMARY_COLOR, THIRD_COLOR } from 'styles/colors';

const headerStyle = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px',
    height: 72,
  },
  appBar: {
    backgroundColor: `${PRIMARY_COLOR} !important`,
    color: `${THIRD_COLOR} !important`,
    boxShadow: 'none',
  },
  appBarShift: {
    marginLeft: 330,
    // width: `calc(100% - ${330}px)`,
    width: '100%',
  },
  categoryDropdown: {
    width: '9.5%',
    cursor: 'pointer',
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
  },
  expandIcon: {
    fontSize: 24,
    verticalAlign: 'middle',
    marginLeft: 5,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  logoImg: {
    marginRight: 80,
    marginLeft: 80,
  },
  logoImgScene: {
    width: 110,
  },
  logoImgMfh: {
    width: 60,
  },
}));

export default headerStyle;
