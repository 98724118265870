import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { PRIMARY_COLOR, SECONDARY_COLOR } from 'styles/colors';

const pnfStyle = makeStyles<Theme>((theme) => ({
  pnfContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  pnfIcon: {
    fontSize: '125px !important',
    color: SECONDARY_COLOR,
    marginTop: '10%',
  },
  pnfTxt: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: PRIMARY_COLOR,
    marginTop: '20px',
  },
}));

export default pnfStyle;
