import { Button } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import ClausesWindow from './ClausesWindow';
import EducationWindow from './EducationWindow';

const DEFAULT_VALUE = '+';
const EDUCATIONAL_RIGHT = 'Educational';

interface RightsBodyProps {
  classes: any;
  licenseWindow: { currentOpenTab: string };
  operationMode: string;
  licenseType: string;
  licenseTemplate: any;
  rights: string[];
  isButtonDisabled: any;
  handleSaveClick: () => void;
  getEnabledSubRights: (rightName: string) => string[];
  selectedSubrightStyle: any;
  selectedSubright: any;
  handleSetSelectedSubright: any;
}

const RightsBody: React.FC<RightsBodyProps> = ({
  classes,
  licenseWindow,
  operationMode,
  licenseType,
  licenseTemplate,
  rights,
  isButtonDisabled,
  handleSaveClick,
  getEnabledSubRights,
  selectedSubrightStyle,
  selectedSubright,
  handleSetSelectedSubright,
}) => {
  //   const [selectedSubright, setSelectedSubright] = useState<string | null>(null);
  const currentOpenTab = licenseWindow.currentOpenTab;
  const boxDisplay = currentOpenTab !== DEFAULT_VALUE ? 'block' : 'none';
  const boxMargin = currentOpenTab !== DEFAULT_VALUE ? '0px' : '150px';

  console.log('TEMPLATE LICENÇA', licenseTemplate);
  console.log('SELECTED SUBRIGHT', selectedSubright);

  let BodyButton = <></>;
  if (operationMode === 'create') {
    BodyButton = (
      <Button
        className={clsx(classes.licenseButton, classes.saveBtn)}
        disabled={isButtonDisabled(currentOpenTab)}
        onClick={() => handleSaveClick()}
      >
        Apply
      </Button>
    );
  }

  let currentBody;

  if (rights.findIndex((element) => element === currentOpenTab) !== -1) {
    const rightName = currentOpenTab;
    // Education Tab
    if (rightName === EDUCATIONAL_RIGHT) {
      currentBody = (
        <>
          <EducationWindow
            rightName={rightName}
            licenseType={licenseType}
            operationMode={operationMode}
            classes={classes}
            license={licenseTemplate.nonCommercialRights.Educational} //TODO: Check if there is another way to pass license info
          />

          {BodyButton}
        </>
      );
    } else {
      const subRights = getEnabledSubRights(rightName);
      currentBody = (
        <>
          <div className={classes.licenseSubtitle}>Choose Sub-Rights</div>

          <div className={classes.optionsContainer}>
            {/* Normal Tab Buttons */}
            {subRights.map((subright: any, index: any) => {
              return (
                <Button
                  key={index}
                  className={clsx(
                    classes.licenseButton,
                    selectedSubrightStyle(subright)
                  )}
                  onClick={() => handleSetSelectedSubright(subright)}
                >
                  {subright}
                </Button>
              );
            })}
          </div>

          {selectedSubright ? (
            <ClausesWindow
              classes={classes}
              rightName={rightName}
              subRightName={selectedSubright}
              licenseType={licenseType}
              operationMode={operationMode}
            />
          ) : (
            <></>
          )}
          {BodyButton}
        </>
      );
    }
  }

  return (
    <div style={{ marginBottom: boxMargin }}>
      <div
        className={classes.licenseRightsBody}
        style={{ display: boxDisplay, marginBottom: boxMargin }}
      >
        {currentBody}
      </div>
    </div>
  );
};

export default RightsBody;
