import { Button, Grid, InputAdornment, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link as NavLink } from 'react-router-dom';

import TextInput from 'components/controls/textInput/TextInput';

import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { PRIMARY_COLOR } from 'styles/colors';

import authStyle from 'styles/auth';
import { SECONDARY_COLOR } from 'styles/colors';
import './RegisterPage.css';

import { QueryClient, QueryClientProvider, useMutation } from 'react-query';
import { institutionRegister } from 'services/authService';
import Swal from 'sweetalert2';

import { RegisterBuyerInstitutionModel } from 'mapfilm-api';

interface OwnProps {
  classes?: any;
  history: any;
}

interface State {
  form: RegisterBuyerInstitutionModel;
  errors: {
    emailError: string;
  };
}

const queryClient = new QueryClient();

const RegisterInstitutionWrapper: React.FC<OwnProps> = (props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <RegisterInstitution {...props} />
    </QueryClientProvider>
  );
};

const RegisterInstitution: React.FC<OwnProps> = () => {
  const classes = authStyle();
  const [form, setForm] = useState<RegisterBuyerInstitutionModel>({
    orgName: '',
    orgAddress: '',
    representativeEmail: '',
  });

  // console.log(form);

  const [errors, setErrors] = useState<{ emailError: string }>({
    emailError: '',
  });
  const [error, setError] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
    setInputStateError(`${name}Error`, '');
  };

  const inputStyle = (error: string) => {
    const getelement = (errors: any, error: string) => {
      return errors[error];
    };
    const inputErrors: any = getelement(errors, error);
    return inputErrors === '' || inputErrors === 'undefined'
      ? classes.inputTextfield
      : classes.inputTextfieldError;
  };

  const institutionRegisterMutation = useMutation(
    (registerBuyerInstitutionModel: RegisterBuyerInstitutionModel) =>
      institutionRegister(registerBuyerInstitutionModel),
    {
      onError: (error: any) => {
        console.log('erro', error);
        setError(error || 'An error occurred.');
      },
      onSuccess: () => {
        Swal.fire({
          //position: 'top-end',
          icon: 'success',
          iconColor: SECONDARY_COLOR,
          text: 'An institution registration request was sent with success. After the request is approved, an email will be sent to your address with the link to the registration. Only emails with the same address domain can be associated to this institution',
          showConfirmButton: false,
        }).then(() => {
          window.location.href = '/auth/login';
        });
        setError(null);
      },
    }
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const registerInstitutionModel: RegisterBuyerInstitutionModel = {
      orgAddress: form.orgAddress,
      orgName: form.orgName,
      representativeEmail: form.representativeEmail,
    };
    // console.log(registerInstitutionModel);
    institutionRegisterMutation.mutate(registerInstitutionModel);
  };

  const setInputStateError = (errorName: string, errorValue: string) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [errorName]: errorValue,
    }));
  };

  const onBlurHandle = async (name: string) => {
    const errorName = `${name}Error`;
    const errorValue = '';
    const getelement = (form: any, name: string) => {
      return form[name];
    };

    const inputValue = getelement(form, name);
    // console.log('inputVal', inputValue);

    setInputStateError(errorName, errorValue);
  };

  return (
    <div className={classes.authFormContainer}>
      <div className={classes.authFormBox}>
        <div className={classes.titleContainer}>
          <Typography
            component='h1'
            variant='h5'
            classes={{ root: classes.registerTitle }}
          >
            <span className={classes.changeColor}>Institution</span>{' '}
            registration
          </Typography>
          <p className={classes.aboveSubtitle}>
            Register your institution to be able to sign in into the application
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid className={classes.formContainer} container spacing={3}>
            <Grid item xs={12} sm={12}>
              <TextInput
                id='orgName'
                className={`${inputStyle('orgName')}`}
                type='text'
                label='Institution'
                name='orgName'
                placeholder='Enter your institution name'
                value={form.orgName}
                setInputValue={handleChange}
                disabled={false}
                required={true}
                autoComplete='orgName'
                onBlur={() => onBlurHandle('orgName')}
                // helperText={this.state.errors.usernameError}
                // error={this.state.errors.usernameError !== ''}
                startAdornment={
                  <InputAdornment
                    classes={{ positionStart: classes.positionStart }}
                    position='start'
                  >
                    <WorkIcon />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextInput
                id='orgAddress'
                className={`${inputStyle('orgAddress')}`}
                type='text'
                label='Address'
                name='orgAddress'
                placeholder='Enter your institution address'
                value={form.orgAddress}
                setInputValue={handleChange}
                disabled={false}
                required={true}
                autoComplete='orgAddress'
                onBlur={() => onBlurHandle('orgAddress')}
                // helperText={this.state.errors.usernameError}
                // error={this.state.errors.usernameError !== ''}
                startAdornment={
                  <InputAdornment
                    classes={{ positionStart: classes.positionStart }}
                    position='start'
                  >
                    <LocationOnIcon />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextInput
                id='representativeEmail'
                className={`${inputStyle('emailError')}`}
                type='email'
                label='Your Academic Email'
                name='representativeEmail'
                placeholder='Enter your email address'
                value={form.representativeEmail}
                setInputValue={handleChange}
                disabled={false}
                required={true}
                autoComplete='representativeEmail'
                onBlur={() => onBlurHandle('representativeEmail')}
                helperText={errors.emailError}
                error={errors.emailError !== ''}
                startAdornment={
                  <InputAdornment
                    classes={{ positionStart: classes.positionStart }}
                    position='start'
                  >
                    <EmailIcon />
                  </InputAdornment>
                }
              />
            </Grid>

            {error && (
              <Typography color='error' style={{ margin: '20px' }}>
                {error}
              </Typography>
            )}
          </Grid>
          <div
            className={classes.registerOptions}
            style={{ marginTop: '15px' }}
          >
            <Button
              type='submit'
              fullWidth
              variant='contained'
              // color='default'
              className={classes.submit}
              disabled={
                form.orgName === '' ||
                form.orgAddress === '' ||
                form.representativeEmail === '' ||
                institutionRegisterMutation.isLoading
              }
              onClick={(e: any) => handleSubmit(e)}
            >
              {institutionRegisterMutation.isLoading ? (
                <Box sx={{ display: 'inline', paddingTop: '8px' }}>
                  <CircularProgress
                    sx={{
                      color: PRIMARY_COLOR,
                      width: '25px !important',
                      height: '25px !important',
                    }}
                  />
                </Box>
              ) : (
                'Register'
              )}
            </Button>

            <div className={classes.loginLinkContainer}>
              <Typography className={classes.authTxt}>
                {'Already registered?'}
              </Typography>
              <Link
                className={classes.authLink}
                component={NavLink}
                to='/auth/login'
              >
                Sign in
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterInstitutionWrapper;
