import AuthSplash from 'components/authSplash/AuthSplash';
import React from 'react';
import authStyle from 'styles/auth';

interface OwnProps {
  history: any;
  children: React.ReactElement;
}

const AuthLayout: React.FC<OwnProps> = ({ history, children }) => {
  const classes = authStyle();

  return (
    <div className={classes.authContainer}>
        <AuthSplash />

        {children}
      </div>
  );
};

export default AuthLayout;
