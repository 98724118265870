import React from 'react';

import InfoRoundedIcon from '@mui/icons-material/Info';

import { project } from 'project';
import { projectImageConfig } from 'utils/projectFieldsDefinitions';
import uStyle from './UStyles';

interface Props {
  classes?: any;
  history: any;
  location: any;
}

const Unsupported: React.FC<Props> = () => {
  const uStyleClasses = uStyle();
  const { name, logo } =
    projectImageConfig[project] || projectImageConfig.default;

  return (
    <div className={uStyleClasses.pageContainer}>
      <img className={uStyleClasses.mapLogo} src={logo} alt={`${name} logo`} />
      <div className={uStyleClasses.unsupportedContent}>
        <InfoRoundedIcon className={uStyleClasses.unsupportedIcon} />

        <span className={uStyleClasses.unsupportedTxt}>
          Oops! Screen Resolution Too Small
        </span>

        <span className={uStyleClasses.unsupportedDescription}>
          {name} isn&apos;t optimized for your current screen size.{' '}
          <br className={uStyleClasses.divisor} />
          For the best experience, please maximize your window or switch to a
          larger device (desktop computer).
        </span>
      </div>
    </div>
  );
};

export default Unsupported;
