import React from 'react';

import InfoRoundedIcon from '@mui/icons-material/Info';
import pnfStyle from 'pages/pagenotfound/PageNotFoundStyles';

interface Props {
  classes?: any;
}

const NoAcessUser: React.FC<Props> = () => {
  const pageClasses = pnfStyle();
  return (
    <div className={pageClasses.pnfContent}>
      <InfoRoundedIcon className={pageClasses.pnfIcon} />

      <span className={pageClasses.pnfTxt}>
        Sorry, you don&apos;t have access to the available films yet, please
        speak to the responsible teacher for your institution.
      </span>
    </div>
  );
};

export default NoAcessUser;
