import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { PRIMARY_COLOR, SECONDARY_COLOR } from './colors';

let theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  palette: {
    background: {
      default: PRIMARY_COLOR,
    },
    common: {
      black: PRIMARY_COLOR,
    },
    primary: {
      main: SECONDARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    text: {
      primary: PRIMARY_COLOR,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
