import DoneIcon from '@mui/icons-material/Done';
import { Button, Typography } from '@mui/material';
import clsx from 'clsx';
import Loading from 'components/loadingButton/LoadingBtn';
import React from 'react';

interface RightsHeaderProps {
  classes: any;
  rights: string[];
  loading: any;
  licenseTemplate: { filmTitle?: string };
  selectedRightStyle: (item: string) => string;
  selectRight: (item: string) => void;
  checkIfSaved: (item: string) => boolean;
}

const RightsHeader: React.FC<RightsHeaderProps> = ({
  classes,
  rights,
  loading,
  licenseTemplate,
  selectedRightStyle,
  selectRight,
  checkIfSaved,
}) => {
  if (loading) {
    // return <Typography>Loading...</Typography>;
    return <Loading />;
  }

  // if (rights.length === 0) {
  //   return (
  //     <Typography className={classes.noLicenses}>
  //       No template was created for this product!
  //     </Typography>
  //   );
  // }

  return (
    <div>
      <Typography className={classes.licenseMovieTitle}>
        {licenseTemplate?.filmTitle || ''}
      </Typography>
      <div className={classes.licenseTitle}>Choose Rights</div>
      <div className={classes.optionsContainer}>
        {rights.map((item, index) => (
          <Button
            key={index}
            className={clsx(classes.licenseButton, selectedRightStyle(item))}
            onClick={() => selectRight(item)}
          >
            {item}
            {checkIfSaved(item) && <DoneIcon className={classes.doneIcon} />}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default RightsHeader;
