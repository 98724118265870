import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Checkbox,
  FormLabel,
  TextField,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import { ChangeEvent, FC } from 'react';

import autocompleteStyle from './AutocompleteStyles';

interface OwnProps {
  id: string;
  classes?: any;
  label: string;
  name: string;
  value: any;
  pageType: any;
  options: any[];
  placeholder: string;
  disabled?: boolean;
  auth: boolean;
  required: boolean;
  getOptionLabel: (option: any) => string;
  onChange: (
    event: ChangeEvent<unknown>,
    newValue: any[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => void;
  getOptionSelected: (option: any, value: any) => boolean;
  selectedOptions: any;
}

const AutocompleteInputSelects: FC<OwnProps> = ({
  label,
  id,
  options,
  value,
  disabled = false,
  placeholder,
  auth,
  required,
  getOptionLabel,
  getOptionSelected,
  onChange,
  pageType,
}) => {
  const classes = autocompleteStyle();
  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  return (
    <>
      <FormLabel
        className={pageType === 'product' ? classes.labelBlack : classes.label}
        component='legend'
      >
        {label}
      </FormLabel>
      <Autocomplete
        multiple
        classes={{
          root: classes.autocompleteRoot,
          inputRoot: auth
            ? classes.autocompleteInputAuthSelect
            : classes.autocompleteInput,
        }}
        id={id}
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={(option, val) => option === val}
        onChange={onChange}
        value={value}
        disabled={disabled}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {getOptionLabel(option)}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant='outlined'
            required={required}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                key={key}
                label={getOptionLabel(option)}
                {...tagProps}
                className={classes.chipLabel}
              />
            );
          })
        }
      />
    </>
  );
};

export default AutocompleteInputSelects;
