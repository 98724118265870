import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const stylesStyle = makeStyles<Theme>((theme) => ({
  /* Main Layout */
  root: {
    display: 'flex',
  },
  layoutContent: {
    marginTop: 72,
    position: 'relative',
    flexGrow: 1,
    height: 'calc(100vh - 72px)',
    overflow: 'auto',
    backgroundColor: '#F6F4F2',
  },
  layoutContainer: {
    minHeight: 'calc(100vh - 160px - 72px)',
    // padding: '50px 0px',
    padding: '30px 0px',
    // width: '80%',
    width: '93%',
    margin: '0 auto',
    '@media (max-width: 1600px)': {
      // width: '85%',
      width: '90%',
    },
  },

  /* Poster */
  posterFrame: {
    paddingTop: '1em',
    textAlign: 'center',
    // backgroundColor:'blue',
    padding: '0.5em',
  },
  productPoster: {
    border: '1px solid lightGrey',
    borderRadius: '1em',
    //borderColor:'green'
  },
}));

export default stylesStyle;
