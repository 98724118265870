import { Checkbox, Typography } from '@mui/material';
import licenseStyle from 'components/licenses/styles/LicenseStyles';
import { CustomTerritoryModel } from 'models/CustomLicenseModel';
import { TemplateTerritoryModel } from 'models/LicenseTemplateModel';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'redux/store';
import {
  CustomLicenseActions,
  LicenseTemplateActions,
} from '../../../../redux/actions';

interface OwnProps {
  classes?: any;
  rightName: any;
  subRightName: string;
  clauseName: string;
  territoryId: string; // if create is from template license, else is from custom license
  territoryName: string; // Template name
  licenseType: string;
}

interface DispatchProps {
  updateExclusivityLT: (payload: any) => void;
  updateExclusivityCL: (payload: any) => void;
}

interface StateProps {
  exclusivity: boolean;
  nonExclusivity: boolean;
  exclusivityTemplate: boolean;
  nonExclusivityTemplate: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

const ExclusivityComponent: React.FC<Props> = (props) => {
  const classes = licenseStyle();
  const handleChange = (event: any) => {
    const payload = {
      rightName: props.rightName,
      subRightName: props.subRightName,
      clauseName: props.clauseName,
      territoryId: props.territoryId,
      exclusivity:
        event.target.name === 'exclusivity'
          ? event.target.checked
          : props.exclusivity, // just update if the specific input is changed
      nonExclusivity:
        event.target.name === 'nonExclusivity'
          ? event.target.checked
          : props.nonExclusivity,
    };

    if (props.licenseType === 'template') props.updateExclusivityLT(payload);

    // Only update if option is available
    if (
      (props.licenseType === 'custom' &&
        props.exclusivityTemplate &&
        event.target.name === 'exclusivity') ||
      (props.licenseType === 'custom' &&
        props.nonExclusivityTemplate &&
        event.target.name === 'nonExclusivity')
    ) {
      props.updateExclusivityCL(payload);
    }
  };

  return (
    <div className={classes.exclusivityContainer}>
      <Typography align='left' className={classes.territoryLabel}>
        {props.licenseType === 'template'
          ? 'Allow Exclusivity:'
          : props.licenseType === 'custom'
            ? 'Exclusivity:'
            : ''}
        <Checkbox
          className={classes.checkboxIcon}
          color='default'
          name='exclusivity'
          disabled={
            props.licenseType === 'custom' ? !props.exclusivityTemplate : false
          }
          checked={props.exclusivity}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'Exclusivity checkbox' }}
        />
      </Typography>
      <Typography align='left' className={classes.territoryLabel}>
        {props.licenseType === 'template'
          ? 'Allow Non-exclusivity:'
          : props.licenseType === 'custom'
            ? 'Non-exclusivity:'
            : ''}
        <Checkbox
          className={classes.checkboxIcon}
          color='default'
          name='nonExclusivity'
          disabled={
            props.licenseType === 'custom'
              ? !props.nonExclusivityTemplate
              : false
          }
          checked={props.nonExclusivity}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'Non-exclusivity checkbox' }}
        />
      </Typography>
    </div>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const territories =
    state.licenseTemplate.commercialRights[ownProps.rightName].subRights[
      ownProps.subRightName
    ].clauses[ownProps.clauseName].territories;
  let exclusivity = false;
  let exclusivityTemplate = false;
  let nonExclusivityTemplate = false;
  let nonExclusivity = false;

  if (ownProps.licenseType === 'template') {
    const territory = territories.find(
      (element: TemplateTerritoryModel) =>
        element.territoryId === ownProps.territoryId
    );

    exclusivity =
      territory !== undefined ? territory.exclusivityAllowed : false;
    nonExclusivity =
      territory !== undefined ? territory.nonExclusivityAllowed : false;
    exclusivityTemplate = false;
    nonExclusivityTemplate = false;
  }
  if (ownProps.licenseType === 'custom') {
    const territory = territories.find(
      (element: TemplateTerritoryModel) =>
        element.territoryName === ownProps.territoryName
    );
    if (ownProps.territoryName !== '' && territory !== undefined) {
      exclusivityTemplate = territory.exclusivityAllowed;
      nonExclusivityTemplate = territory.nonExclusivityAllowed;
    }

    const customTerritory = state.customLicense.commercialRights[
      ownProps.rightName
    ][ownProps.subRightName][ownProps.clauseName].territories.find(
      (element: CustomTerritoryModel) =>
        element.territoryId === ownProps.territoryId
    );

    exclusivity =
      customTerritory !== undefined ? customTerritory.exclusivity : false;
    nonExclusivity =
      customTerritory !== undefined ? customTerritory.nonExclusivity : false;
  }
  return {
    exclusivity: exclusivity, // Input version
    nonExclusivity: nonExclusivity, // Input Version
    exclusivityTemplate: exclusivityTemplate, // Used only on Custom mode. Used to filter availability
    nonExclusivityTemplate: nonExclusivityTemplate, // Used only on Custom mode. Used to filter availability
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      updateExclusivityLT: LicenseTemplateActions.updateExclusivityLT,
      updateExclusivityCL: CustomLicenseActions.updateExclusivityCL,
    },
    dispatch
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ExclusivityComponent);
