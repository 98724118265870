import { Box, Typography } from '@mui/material';
import { UserModel } from 'models';
import React from 'react';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import {
  checkDownloads,
  getresetDownloadsLimits,
} from 'services/productService';

import { FilmCounterDownload } from 'styles/dashboard';


interface DownloadsCounterProps {
  user: UserModel;
}

const DownloadsCounter: React.FC<DownloadsCounterProps> = ({ user }) => {
  const { data: downloadCount, isLoading: downloadsLoading } = useQuery(
    ['downloadCount'],
    () => checkDownloads()
  );

  const { data: downloadsInfo, isSuccess: downloadsInfoSuccess } = useQuery(
    ['downloadLimit'],
    () => getresetDownloadsLimits()
  );

  const downloadLimit =
    downloadsInfoSuccess && downloadsInfo ? downloadsInfo.downloadLimit : 10;

  let displayCount = '';

  if (user.role === 'staff') {
    displayCount = downloadCount;
  } else if (user.role === 'user') {
    displayCount = `${downloadCount} / ${downloadLimit}`;
  }

  return (
    <Box sx={FilmCounterDownload.filmCounter}>
      <Box sx={FilmCounterDownload.filmCounter.text}>
        <Typography variant='h3'>{displayCount}</Typography>
        <Typography variant='h4'>Downloaded films</Typography>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
});

const mapDispatchToProps = {};

const ConnectedDownloadsCounter = connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadsCounter);

export default ConnectedDownloadsCounter;
