import { Button, Link, Typography } from '@mui/material';
import { Link as NavLink } from 'react-router-dom';
import authStyle from 'styles/auth';

interface OwnProps {
  classes?: any;
}

const PrivacyPolicy: React.FC = () => {
  const classes = authStyle();
  return (
    <div className={classes.authFormContainer}>
      <div className={classes.termsBox}>
        <Typography component='h1' variant='h5' className={classes.termsTitle}>
          Privacy Policy
        </Typography>

        <Typography component='p' className={classes.lastUpdate}>
          Last updated [7 October 2021]
        </Typography>

        <Typography component='h2' className={classes.privacySubtitle}>
          Our Data Protection Officer contact details:
        </Typography>

        <Typography component='p' className={classes.termsContact}>
          Name: Stephen Wilkinson
        </Typography>

        <Typography component='p' className={classes.termsContact}>
          Address:
        </Typography>

        <Typography component='p' className={classes.termsContact}>
          Ealing Studios
          <br />
          Ealing Green
          <br />
          London
          <br />
          W5 5EP
          <br />
          United Kingdom
          <br />
        </Typography>

        <Typography component='p' className={classes.termsContact}>
          Phone Number: 44 (0) 20 8280 9127
        </Typography>

        <Typography component='p' className={classes.termsContact}>
          E-mail:{' '}
          <Link className={classes.authLink} href='mailto:stevew@metfilm.co.uk'>
            stevew@metfilm.co.uk
          </Link>
        </Typography>

        <Typography component='h2' className={classes.privacySubtitle}>
          Introduction
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          This policy describes how we collect, store, transfer and use personal
          data. It tells you about your privacy rights and how the law protects
          you.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          In the context of the law and this policy, ‘personal data’ is
          information that clearly identifies you as an individual or which
          could be used to identify you if combined with other information.
          Acting in any way on personal data is referred to as ‘processing’.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          This policy applies to personal data collected through our website.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          Except as set out below, we do not share, or sell, or disclose to a
          third party, any information collected through our website.
        </Typography>

        <Typography component='h2' className={classes.privacySubtitle}>
          Data Protection Officer
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          We have appointed a data protection officer (‘DPO’) who is responsible
          for ensuring that our privacy policy is followed. If you have any
          questions about how we process your personal data, including any
          requests to exercise your legal rights, please contact our DPO,
          Stephen Wilkinson, at{' '}
          <Link className={classes.authLink} href='mailto:stevew@metfilm.co.uk'>
            stevew@metfilm.co.uk
          </Link>
          .
        </Typography>

        <Typography component='h2' className={classes.privacySubtitle}>
          The type of personal information we collect
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          We currently collect and process the following information:
        </Typography>

        <ul className={classes.termsList}>
          <li>
            personal identifiers, in particular your first and last names, your
            academic or business affiliation
          </li>

          <li>
            contact information, in particular your email address, your
            institutional or business address
          </li>

          <li>
            account information, including your username and password (your
            password is stored in a hashed format and is not readable by us)
          </li>

          <li>
            additional categories of personal data, such as first and last
            names, business addresses, etc., related to (i) licensing agreements
            for your films (as buyer or seller), and (ii) film information
            uploaded to the platform.
          </li>
        </ul>

        <Typography component='h2' className={classes.privacySubtitle}>
          How we get the personal information and why we have it
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          The personal information we process is provided to us directly by you
          for one of the following reasons:
        </Typography>
        <ul className={classes.termsList}>
          <li>Setting up a user account</li>

          <li>Setting up or signing a licensing agreement</li>

          <li>Uploading or downloading licensing agreements</li>

          <li>Uploading or downloading media assets</li>

          <li>Using our website</li>

          <li>Contacting our customer support staff or the DPO</li>
        </ul>

        <Typography component='p' className={classes.termsTxt}>
          We use <b>session cookies</b>, which allow you to log into the website
          automatically, after signing in. These cookies use a token to complete
          the authentication procedure – however, we do not collect any personal
          information, beyond the token, through the cookies.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          We use the information that you have given us in order to allow you
          to:
        </Typography>
        <ul className={classes.termsList}>
          <li>Navigate our site and use our services</li>

          <li>Set up, sign, upload and download licensing agreements</li>

          <li>Upload and download media assets</li>

          <li>Communicate with us and our site users</li>

          <li>
            Respond to specific requests and notify you of substantial changes
          </li>
        </ul>

        <Typography component='p' className={classes.termsTxt}>
          We share some of this information (including first and last name,
          email address, business/institutional affiliation,
          business/institutional address) with licensees and licensors, when you
          sign a licensing agreement through our website.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          Under the General Data Protection Regulation (GDPR), we rely on your
          consent for processing your personal data. You can withdraw your
          consent at any time, by contacting us at DPO email.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          In certain circumstances, we may need to process your personal
          information following contractual obligations with you (e.g. to
          communicate information concerning your account), or due to a legal
          obligation (e.g. to comply with applicable laws or where information
          is required by the authorities), or if we have a legitimate interest
          (e.g. if you have closed your account and a licensing agreement is
          still in force, or if you have concluded a licensing agreement but
          have since closed your account and a notifiable event, such as the
          termination of a licensing agreement, needs to be communicated to
          you).
        </Typography>

        <Typography component='h2' className={classes.privacySubtitle}>
          How we store your personal information
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          Your account and contact information is securely stored in the cloud
          through MOG Technologies, one of the partners of the MAP Marketplace,
          on servers located within the territory of the European Union. We keep
          this information for as long as you hold an account with us.We will
          then dispose of your information by deleting it from our systems
          within 30 days.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          Licensing information, including contact information, personal
          identifiers and other personal data required for the valid conclusion
          of the licensing agreements, is stored on a blockchain spine.The
          blockchain spine is a distributed ledger that stores information in a
          decentralised manner, which means that each node of the blockchain
          stores a copy of the information.While we plan to make changes to our
          blockchain spine in the future, at the moment your licensing
          information is stored in nodes located in the United Kingdom and the
          European Union. The licensing information is visible to the nodes and
          remains stored for as long as licensing agreements are in force.After
          this period, the information remains recorded on the blockchain as
          past transactions, providing evidence of the existence of past
          contractual obligation, for the limitation periods applicable under
          the laws of England and Wales.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          At the end of the periods indicated above, should any further
          obligation still exist (e.g.a payment or notifiable event), we will
          keep your personal identifiers only for the period strictly needed to
          enforce, or comply with, such obligations.
        </Typography>

        <Typography component='h2' className={classes.privacySubtitle}>
          Your data protection rights
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          Under data protection law, you have rights including:
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          <b>Your right of access</b> - You have the right to ask us for copies
          of your personal information.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          <b>Your right to rectification</b> - You have the right to ask us to
          rectify personal information you think is inaccurate.You also have the
          right to ask us to complete information you think is incomplete.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          <b>Your right to erasure</b> - You have the right to ask us to erase
          your personal information in certain circumstances.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          <b>Your right to restriction of processing</b> - You have the right to
          ask us to restrict the processing of your personal information in
          certain circumstances.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          <b>Your right to object to processing</b> - You have the the right to
          object to the processing of your personal information in certain
          circumstances.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          <b>Your right to data portability</b> - You have the right to ask that
          we transfer the personal information you gave us to another
          organisation, or to you, in certain circumstances.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          You are not required to pay any charge for exercising your rights.If
          you make a request, we have one month to respond to you.
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          Please contact us at{' '}
          <Link className={classes.authLink} href='mailto:stevew@metfilm.co.uk'>
            stevew@metfilm.co.uk
          </Link>{' '}
          if you wish to make a request.
        </Typography>

        <Typography component='h2' className={classes.privacySubtitle}>
          How to complain
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          If you have any concerns about our use of your personal information,
          please contact us at{' '}
          <Link className={classes.authLink} href='mailto:stevew@metfilm.co.uk'>
            stevew@metfilm.co.uk
          </Link>
          .
        </Typography>

        <Typography component='p' className={classes.termsTxt}>
          You can also complain to the ICO if you are unhappy with how we have
          used your data.
        </Typography>

        <Typography component='p' className={classes.termsContact}>
          The ICO’s address:
        </Typography>

        <Typography component='p' className={classes.termsContact}>
          Information Commissioner’s Office
          <br />
          Wycliffe House
          <br />
          Water Lane
          <br />
          Wilmslow
          <br />
          Cheshire
          <br />
          SK9 5AF
          <br />
        </Typography>

        <Typography component='p' className={classes.termsContact}>
          Helpline number: 0303 123 1113
        </Typography>

        <Typography component='p' className={classes.termsContact}>
          ICO website:{' '}
          <Link href='https://www.ico.org.uk' target='_blank'>
            https://www.ico.org.uk
          </Link>
        </Typography>

        <NavLink className={classes.termsLinkBtn} to='/'>
          <Button className={classes.termsBtn} variant='outlined'>
            Back to Home Page
          </Button>
        </NavLink>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
