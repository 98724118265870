import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { FOURTH_COLOR, SECONDARY_COLOR, THIRD_COLOR } from 'styles/colors';

const dropdownStyle = makeStyles<Theme>((theme) => ({
  userIcon: {
    color: SECONDARY_COLOR,
    fontSize: '38px',
    verticalAlign: 'middle',
    marginRight: 8,
  },
  avatar: {
    marginRight: 8,
    letterSpacing: '0.5px',
  },
  expandIcon: {
    fontSize: 24,
    verticalAlign: 'middle',
    marginLeft: 5,
  },
  userDropdown: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginRight: '80px',
    marginLeft: '80px',
    cursor: 'pointer',
  },
  dropdownToggle: {
    display: 'flex',
    alignItems: 'center',
  },
  navbarUsername: {
    fontSize: '16px',
    fontWeight: 500,
  },
  dropdownMenuRight: {
    right: 0,
    left: 'auto',
  },
  dropdownMenu: {
    position: 'absolute',
    top: '100%',
    float: 'left',
    color: THIRD_COLOR,
    textAlign: 'left',
    liststyle: 'none',
    backgroundColor: FOURTH_COLOR,
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
    zIndex: 301,
    fontSize: '0.85rem',
    padding: '10px 0',
    transition: '0.3s',
    minWidth: '210px',
  },
}));

export default dropdownStyle;
