import React from 'react';

import pStyle from './PStyles';

interface IGenresProps {
  genres: string[];
  classes?: any;
}

function ToggleButtonsMultiple(props: IGenresProps) {
  const { genres } = props;
  const pStyleClasses = pStyle();
  // const selectedCategories = genres.filter((genre) => genre.selected === true);

  const [formats, setFormats] = React.useState(() => genres);

  const handleFormat = (event: any, newFormats: any) => {
    setFormats(newFormats);
  };

  return (
    <div>
      {genres.length === 0 ? (
        <span className={pStyleClasses.description}>
          No categories selected
        </span>
      ) : (
        <div className={pStyleClasses.genresContainer}>
          {/* <ToggleButtonGroup
            className={classes.buttonGroup}
            value={formats}
            onChange={handleFormat}
          >
            {genres.map((genre: string, index: number) => {
              return (
                <ToggleButton
                  classes={{
                    root: classes.toggleButton,
                    selected: classes.toggleButtonSelected,
                  }}
                  key={index}
                  value={genre}
                  disabled={true}
                >
                  {genre}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup> */}
          <div className={pStyleClasses.genreFlexContainer}>
            {genres.map((genre: string, index: number) => {
              return (
                <span key={index} className={pStyleClasses.genreItem}>
                  {genre}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default ToggleButtonsMultiple;
