import loadingStyle from './LoadingStyles';

function MLoading({ xs }: { xs?: boolean }) {
  const loadingStyleClasses = loadingStyle();
  let loadingClasses = loadingStyleClasses.loading;

  if (xs) {
    loadingClasses += ' loading-xs';
  }

  const renderItems = () => {
    return Array.from({ length: 5 }, (_, index) => (
      <div
        key={index}
        className={`${loadingStyleClasses.item} ${loadingStyleClasses[`right${index + 1}`]} ${
          loadingStyleClasses.rightAnimation
        }`}
      ></div>
    ));
  };

  return <div className={loadingClasses}>{renderItems()}</div>;
}

export default MLoading;
